import React from 'react';

import clsx from 'clsx';

import GlobalFilter from './GlobalFilter';
import { Toolbar, Typography, Tooltip, IconButton } from '@mui/material';
import { Box, display, lighten } from '@mui/system';
import AddActorDialog from './AddActorDialog';
import { DeleteRounded } from '@mui/icons-material';

// const useToolbarStyles = makeStyles(theme => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
//   title: {
//     flex: '1 1 100%',
//   },
// }));

const TableToolbar = props => {
  //const classes = useToolbarStyles()
  const {
    numSelected,
    addUserHandler,
    deleteUserHandler,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    setDialogAddPostOpen,
    dialogAddPostOpen,
    selectedRow,
    tableOperation,
    setTableOperation,
  } = props;
  return (
    <Toolbar
      variant="regular"
      // className={clsx(classes.root, {
      //   [classes.highlight]: numSelected > 0,
      // })}
    >
      <AddActorDialog
        addUserHandler={addUserHandler}
        setDialogAddPostOpen={setDialogAddPostOpen}
        dialogAddPostOpen={dialogAddPostOpen}
        selectedRow={selectedRow}
        tableOperation={tableOperation}
        setTableOperation={setTableOperation}
      />
      <Box sx={{ display: 'flex', mx: 1 }}>
        {numSelected > 0 ? (
          <Typography
            //className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            //className={classes.title}
            sx={{ placeSelf: 'center' }}
            variant="h6"
            id="tableTitle"
          >
            Users
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={deleteUserHandler}>
              <DeleteRounded />
            </IconButton>
          </Tooltip>
        ) : (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </Box>
    </Toolbar>
  );
};

// TableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   addUserHandler: PropTypes.func.isRequired,
//   deleteUserHandler: PropTypes.func.isRequired,
//   setGlobalFilter: PropTypes.func.isRequired,
//   preGlobalFilteredRows: PropTypes.array.isRequired,
//   globalFilter: PropTypes.string.isRequired,
// }

export default TableToolbar;
