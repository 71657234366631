import { SearchRounded } from '@mui/icons-material';
import {
  makeStyles,
  InputBase,
  Box,
  TextField,
  Divider,
  IconButton,
  Paper,
} from '@mui/material';
import React from 'react';

// const useStyles = makeStyles(theme => ({
//   search: {
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: fade(theme.palette.common.white, 0.15),
//     '&:hover': {
//       backgroundColor: fade(theme.palette.common.white, 0.25),
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       marginLeft: theme.spacing(3),
//       width: 'auto',
//     },
//   },
//   searchIcon: {
//     width: theme.spacing(7),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   inputRoot: {
//     color: 'inherit',
//   },
//   inputInput: {
//     padding: theme.spacing(1, 1, 1, 7),
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: 200,
//     },
//   },
// }));

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  //const classes = useStyles();
  const count = preGlobalFilteredRows.length;

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  return (
    <Paper
      variant="outlined"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        ml: 2,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={globalFilter || ''}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} records...`}
        // classes={{
        //   root: classes.inputRoot,
        //   input: classes.inputInput,
        // }}
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchRounded />
      </IconButton>
    </Paper>
    // <Box sx={{ display: 'flex', ml: 2 }} className={'classes.search'}>
    //   <Box sx={{ placeSelf: 'center', mr: 1 }} className={'classes.searchIcon'}>
    //     <SearchRounded />
    //   </Box>
    //   <TextField
    //     value={globalFilter || ''}
    //     onChange={e => {
    //       setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    //     }}
    //     placeholder={`${count} records...`}
    //     // classes={{
    //     //   root: classes.inputRoot,
    //     //   input: classes.inputInput,
    //     // }}
    //     variant="outlined"
    //     inputProps={{ 'aria-label': 'search' }}
    //   />
    // </Box>
  );
};

// GlobalFilter.propTypes = {
//   preGlobalFilteredRows: PropTypes.array.isRequired,
//   globalFilter: PropTypes.string.isRequired,
//   setGlobalFilter: PropTypes.func.isRequired,
// };

export default GlobalFilter;
