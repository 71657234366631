import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Management } from '../ManagementPage/Management';
import { AppBar, Button, Drawer, ListItem } from '@mui/material';
import { Login } from '../LoginPage/Login';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalReducer } from 'types/globalReducer';
import {
  apiStoreLoggedUser,
  logout,
  uiClearSessionAuth,
  uiSetSessionAuth,
  UIToggleGlobalModal,
} from 'actions/actionCreator';
import { User } from 'types/user';
import { Socket } from 'socket.io-client';
import AuthenticationAPI from '../../../api/authentication';
let CryptoJS = require('crypto-js');
export default function MainDrawer() {
  const [open, setOpen] = React.useState(false);
  const isAuth = useSelector((state: GlobalReducer) => state.apiData.isAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData: User = useSelector(
    (state: GlobalReducer) => state.apiData.userData,
  );
  const socket: Socket = useSelector(
    (state: GlobalReducer) => state.SocketHandler.socket,
  );
  const timeRemind: NodeJS.Timeout = useSelector(
    (state: GlobalReducer) => state.sessionAuth.timeRemind,
  );
  const timeLogout: NodeJS.Timeout = useSelector(
    (state: GlobalReducer) => state.sessionAuth.timeLogout,
  );

  React.useEffect(() => {
    const MS_REMIND = 3360000;
    const MS_LOGOUT = 3540000;

    // let timeRemind = setInterval(() => {
    //   funcPromtReAuth();
    // }, MS_REMIND);

    // let timeLogout = setInterval(() => {
    //   funcLogout();
    // }, MS_LOGOUT);
    dispatch(
      uiSetSessionAuth(
        setInterval(() => {
          funcPromtReAuth();
        }, MS_REMIND),
        setInterval(() => {
          funcLogout();
        }, MS_LOGOUT),
      ),
    );

    const funcReAuth = async () => {
      const reauthUserData = await AuthenticationAPI.reAuthenticate(
        dispatch,
        socket.id ? socket.id : '',
      );
      // console.log('REAUTH DATA ===>', reauthUserData);
      if (reauthUserData.status === 200) {
        const _userData = reauthUserData.data.user;
        const enLUD = CryptoJS.AES.encrypt(
          JSON.stringify(userData),
          process.env.REACT_APP_SEK,
        );

        localStorage.setItem('LUT', reauthUserData.data.accessToken);
        localStorage.setItem('LUD', enLUD);
        dispatch(uiClearSessionAuth());
        dispatch(apiStoreLoggedUser(_userData, () => {}));

        dispatch(
          uiSetSessionAuth(
            setInterval(() => {
              funcPromtReAuth();
            }, MS_REMIND),
            setInterval(() => {
              funcLogout();
            }, MS_LOGOUT),
          ),
        );
      } else {
        dispatch(uiClearSessionAuth());
      }

      // clearInterval(timeRemind);
      // clearInterval(timeLogout);

      // timeLogout = setInterval(() => {
      //   dispatch(logout(() => localStorage.clear()));
      // }, MS_LOGOUT);
      // timeRemind = setInterval(() => {
      //   funcPromtReAuth();
      // }, MS_REMIND);
    };
    const funcLogout = () => {
      dispatch(
        UIToggleGlobalModal({
          isOpen: false,
        }),
      );
      // clearInterval(timeRemind);
      // clearInterval(timeLogout);
      dispatch(uiClearSessionAuth());
      dispatch(logout(() => localStorage.clear()));
    };
    const funcPromtReAuth = async () => {
      dispatch(
        UIToggleGlobalModal({
          title: 'Session Expiration',
          description: (
            <>
              <Typography>You will be logged out after 3 minutes</Typography>
              <Typography>Do you want to stay logged-in?</Typography>
            </>
          ),
          cb: async () => {
            funcReAuth();
          },
          cbNo: () => {
            funcLogout();
          },
          isYesNo: true,
          isOpen: true,
        }),
      );
    };

    //clearInterval(interval);
    return () => {
      clearInterval(timeRemind);
      clearInterval(timeLogout);
    }; // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    };

  const list = () => (
    <Box
      sx={{}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {/* {['Content Management', 'User Management'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
        <ListItem
          onClick={e => {
            navigate('/post-management');
          }}
          button
        >
          {/* <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon> */}
          <ListItemText primary={'Content Management'} />
        </ListItem>
        {userData.type?.includes(0) && (
          <ListItem
            onClick={e => {
              navigate('/user-management');
            }}
            button
          >
            {/* <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon> */}
            <ListItemText primary={'User Management'} />
          </ListItem>
        )}
      </List>
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </Box>
  );
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Drawer anchor={'left'} open={open} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Tigbauan CMS
            </Typography>
            <Typography component="p" sx={{ px: 2 }}>
              {userData.name}
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'error.light',
                color: 'background.default',
                mx: 1,
              }}
              onClick={e => {
                dispatch(
                  UIToggleGlobalModal({
                    title: 'Confirmation',
                    description: <>Are you sure you want to logout?</>,
                    isOpen: true,
                    isYesNo: true,

                    cb: () => {
                      dispatch(uiClearSessionAuth());
                      dispatch(
                        logout(() => {
                          localStorage.clear();
                          navigate('/');
                        }),
                      );
                    },
                  }),
                );
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
{
}
