import {
  Filter,
  Filter1Rounded,
  FilterAlt,
  SearchRounded,
} from '@mui/icons-material';
import {
  makeStyles,
  InputBase,
  Box,
  TextField,
  Divider,
  IconButton,
  Paper,
  FormControlLabel,
  Switch,
  Stack,
  Grid,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { specialPages } from 'types/links';
import Select from 'react-select';
// const useStyles = makeStyles(theme => ({
//   search: {
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: fade(theme.palette.common.white, 0.15),
//     '&:hover': {
//       backgroundColor: fade(theme.palette.common.white, 0.25),
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       marginLeft: theme.spacing(3),
//       width: 'auto',
//     },
//   },
//   searchIcon: {
//     width: theme.spacing(7),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   inputRoot: {
//     color: 'inherit',
//   },
//   inputInput: {
//     padding: theme.spacing(1, 1, 1, 7),
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: 200,
//     },
//   },
// }));
const customSelStyles = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    // borderBottom: '1px dotted pink',
    // color: state.selectProps.menuColor,
    // padding: 20,
    zIndex: 500,
  }),

  control: (provided, { selectProps: { error } }) => ({
    ...provided,
    borderColor: error ? 'red' : 'grey',
    boxShadow: 'none',
    width: '15rem',
    height: '100%',
    '&:hover': {
      borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.87)',
    },
  }),

  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // },
};
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  selPostFilterPostLoc,
  setSelPostFilterPostLoc,
  chkAllFilter,
  setChkAllFilter,
  chkIsAnnouncement,
  setChkIsAnnouncement,
}) => {
  //const classes = useStyles();
  const count = preGlobalFilteredRows.length;

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid spacing={1} container>
        <Grid item>
          <Paper
            variant="outlined"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '15rem',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={globalFilter || ''}
              onChange={e => {
                setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder={`${count} records...`}
              // classes={{
              //   root: classes.inputRoot,
              //   input: classes.inputInput,
              // }}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchRounded />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item sx={{ alignSelf: 'center' }}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            onClick={handleClick}
            disableElevation
          >
            <FilterAlt />
            Filter
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { p: 2, overflow: 'visible' } }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Stack>
              <Select
                //@ts-ignore
                styles={customSelStyles}
                options={specialPages}
                // isDisabled={Boolean(chkAllFilter)}
                getOptionValue={item => item.l}
                getOptionLabel={item => item.n}
                placeholder="Select Filter..."
                value={selPostFilterPostLoc}
                onChange={e => {
                  setSelPostFilterPostLoc(e);
                  setChkAllFilter(false);
                }}
              />
              <FormControlLabel
                sx={{ mx: 1 }}
                control={
                  <Switch
                    checked={Boolean(chkAllFilter)}
                    onChange={() => {
                      if (chkAllFilter) {
                        setChkAllFilter(false);
                        setSelPostFilterPostLoc(specialPages[0]);
                      } else {
                        setChkAllFilter(true);
                        setSelPostFilterPostLoc(null);
                      }
                    }}
                  />
                }
                label="All Posts"
              />
              <FormControlLabel
                sx={{ mx: 1 }}
                control={
                  <Switch
                    checked={Boolean(chkIsAnnouncement)}
                    onChange={() => {
                      if (chkIsAnnouncement) {
                        setChkIsAnnouncement(false);
                      } else {
                        setChkIsAnnouncement(true);
                      }
                    }}
                  />
                }
                label="Announcement"
              />
            </Stack>
          </Menu>
        </Grid>
      </Grid>
    </>

    // <Box sx={{ display: 'flex', ml: 2 }} className={'classes.search'}>
    //   <Box sx={{ placeSelf: 'center', mr: 1 }} className={'classes.searchIcon'}>
    //     <SearchRounded />
    //   </Box>
    //   <TextField
    //     value={globalFilter || ''}
    //     onChange={e => {
    //       setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    //     }}
    //     placeholder={`${count} records...`}
    //     // classes={{
    //     //   root: classes.inputRoot,
    //     //   input: classes.inputInput,
    //     // }}
    //     variant="outlined"
    //     inputProps={{ 'aria-label': 'search' }}
    //   />
    // </Box>
  );
};

// GlobalFilter.propTypes = {
//   preGlobalFilteredRows: PropTypes.array.isRequired,
//   globalFilter: PropTypes.string.isRequired,
//   setGlobalFilter: PropTypes.func.isRequired,
// };

export default GlobalFilter;
