import {
  AddCircle,
  CloseRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Switch,
  AppBar,
  Toolbar,
  Typography,
  FormControlLabel,
  Box,
  Paper,
  Grid,
  FormControl,
  FormHelperText,
  Checkbox,
  FormGroup,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'mui-image';
import Select from 'react-select';
import { Post } from 'types/post';
import DatePicker from '@mui/lab/DatePicker';
import ContentAPI from 'api/contentAPI';
import { useDispatch, useSelector } from 'react-redux';

import jsesc from 'jsesc';
import { fileURLToPath } from 'url';
import { GlobalReducer } from 'types/globalReducer';
import { use } from 'i18next';
import {
  apiStoreImageDetails,
  UIToggleGlobalModal,
} from 'actions/actionCreator';
import { TTableOperation } from 'types/table';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Misc from '../../../../functions/misc';
import { groupPagePermissions, specialPages } from 'types/links';
import { initUserType, IUserType, User, userTypes } from 'types/user';
import userAPI from 'api/userAPI';

const initialUser = {
  firstName: '',
  lastName: '',
  age: 0,
  visits: 0,
  status: 'single',
  progress: 0,
  subRows: undefined,
};

function carouselPropsEqual(prev, next) {
  return prev.imgFiles.length === next.imgFiles.length;
}

const ImgCarousel = props => {
  //console.log(props);
  //console.log(imgFiles);
  // const [imgDialogOpen, setImgDialogOpen] = React.useState<Boolean>(false);
  const { tableOperation }: { tableOperation: TTableOperation } = props;
  const { selectedRow }: { selectedRow: Post } = props;

  return (
    <Box>
      <Carousel
        //swipeable={true}
        //infiniteLoop
        showThumbs={false}
        centerMode
        showIndicators={false}
        centerSlidePercentage={84}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button
              disableRipple
              sx={{
                position: 'absolute',
                zIndex: 2,
                top: 'calc(50% - 15px)',
                width: 30,
                height: 30,
                left: 40,
                '& :hover': { backgroundColor: 'none' },
              }}
              onClick={onClickHandler}
            >
              <KeyboardArrowLeft sx={{ fontSize: '6rem', color: 'black' }} />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button
              disableRipple
              sx={{
                position: 'absolute',
                zIndex: 2,
                top: 'calc(50% - 15px)',
                width: 30,
                height: 30,
                right: 40,
                '& :hover': { backgroundColor: 'none' },
              }}
              onClick={onClickHandler}
            >
              <KeyboardArrowRight sx={{ fontSize: '6rem', color: 'black' }} />
            </Button>
          )
        }
      >
        {props.imgFiles.map((file: any, index: number) => (
          <>
            {tableOperation === 'VIEW' ? (
              <React.Fragment key={index}>
                <Image
                  //@ts-ignore
                  src={`${
                    process.env.REACT_APP_API_URL
                  }global/getFile?uniqueId=${
                    selectedRow.uniqueId
                  }&filename=${encodeURIComponent(file.filename)}`}
                  duration={0}
                  fit="cover"
                  height="300px"
                />
                <ImageDialog
                  imgDetailsArray={props.imgDetailsArray}
                  setImgDetailsArray={props.setImgDetailsArray}
                  tableOperation={tableOperation}
                  index={index}
                  file={file}
                />

                <Button
                  sx={{ my: '7.5px', mx: '3.75px' }}
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    props.setPostBanner(file.name);
                    props.setPostBannerPreview(file);
                  }}
                >
                  Set as post header
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                <Image
                  //@ts-ignore
                  src={URL.createObjectURL(file)}
                  duration={0}
                  fit="cover"
                  height="300px"
                />
                <ImageDialog
                  imgDetailsArray={props.imgDetailsArray}
                  setImgDetailsArray={props.setImgDetailsArray}
                  tableOperation={tableOperation}
                  index={index}
                  file={file}
                />

                <Button
                  sx={{ my: '7.5px', mx: '3.75px' }}
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    props.setPostBanner(file.name);
                    props.setPostBannerPreview(file);
                  }}
                >
                  Set as post header
                </Button>
              </React.Fragment>
            )}
          </>
        ))}
      </Carousel>
    </Box>
  );
};
function ImageDialog(props: any) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  interface IImageDetails {
    link: string;
    fileName: string;
    description: string;
  }
  const { tableOperation }: { tableOperation: TTableOperation } = props;

  const [open, setOpen] = React.useState(false);

  const [txtDescription, setTxtDescription] = React.useState('');
  const [txtLink, setTxtLink] = React.useState('');
  const imageDetails = useSelector(
    (state: GlobalReducer) => state.imageDetailDialogData.imageDetails,
  );
  React.useEffect(() => {
    //console.log(tableOperation);
    if (tableOperation === 'VIEW') {
      if (props.file) {
        const imageInfo = imageDetails.find(
          x => x.fileName === props.file?.originalname,
        );
        setTxtDescription(imageInfo?.description);
        setTxtLink(imageInfo?.link);
        // console.log(
        //   'IMAGE INFO ===>',
        //   imageDetails,
        //   imageInfo,
        //   props.file?.filename,
        // );
      }
    }
  }, [props]);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveImageInfo = () => {
    //console.log(imageDetails);

    let imgArray: IImageDetails[] = [];
    //console.log(imgArray, props.file, tableOperation);

    if (tableOperation === 'EDIT') {
      imgArray = imageDetails
        ? JSON.parse(jsesc(imageDetails, { json: true }))
        : [];

      if (
        imgArray.findIndex(x => x.fileName === props.file.originalname) !== -1
      ) {
        imgArray = imgArray.map(val =>
          val.fileName === props.file.originalname
            ? { ...val, description: txtDescription, link: txtLink }
            : val,
        );
      } else {
        const imageInfo = {
          description: txtDescription,
          link: txtLink,
          fileName: props.file.originalname,
        };
        imgArray.push(imageInfo);
      }
    } else {
      imgArray = imageDetails ? JSON.parse(imageDetails) : [];

      if (imgArray.findIndex(x => x.fileName === props.file.fileName) !== -1) {
        imgArray = imgArray.map(val =>
          val.fileName === props.file.name
            ? { ...val, description: txtDescription, link: txtLink }
            : val,
        );
      } else {
        const imageInfo = {
          description: txtDescription,
          link: txtLink,
          fileName: props.file.name,
        };
        imgArray.push(imageInfo);
      }
    }

    dispatch(apiStoreImageDetails(jsesc(imgArray, { json: true })));
    enqueueSnackbar('Image details attached');
    //console.log(imageDetails);

    //props.setImgDetailsArray(jsesc(imgArray, { json: true }));

    //console.log(props.imgDetailsArray);
    //console.log(JSON.parse(jsesc(props.imgDetailsArray, { json: true })));

    //console.log(jsesc(props.imgDetailsArray, { json: true }));
    // console.log(test.description.replace(/^"\/$/i, '"'));
  };

  return (
    <>
      <Button
        sx={{ my: '7.5px', mx: '3.75px' }}
        disableElevation
        variant="contained"
        onClick={handleClickOpen}
      >
        Add Info
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Image Information</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            label="Image Description"
            fullWidth
            variant="standard"
            onChange={e => setTxtDescription(e.target.value)}
            value={txtDescription}
          />
          <TextField
            margin="dense"
            label="Image Link"
            fullWidth
            variant="standard"
            onChange={e => setTxtLink(e.target.value)}
            value={txtLink}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => {
              handleClose();
              saveImageInfo();
              //enqueueSnackbar('Image details saved');
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const MemoizedCarousel = React.memo(ImgCarousel, carouselPropsEqual);
const ImgDropZone = ({ setImgFiles }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return (
    <>
      <Dropzone
        multiple
        onDrop={files => {
          setImgFiles(files);
          enqueueSnackbar('Files attached');
        }}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          return (
            <>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{
                  border: '3px dashed black',
                  my: '15px',
                  p: '15px',
                  backgroundColor: '#eeeeee',
                  height: '100px',
                }}
              >
                <input {...getInputProps()} />
                <Typography sx={{ textAlign: 'center' }}>
                  Drag 'n' drop images here, or click to manually select images
                </Typography>
              </Box>
            </>
          );
        }}
      </Dropzone>
    </>

    // <Box
    //   {...getRootProps({ className: 'dropzone' })}
    //   sx={{ border: '3px dashed black', my: '15px', p: '15px' }}
    // >
    //   <div>
    //     <input {...getInputProps()} />
    //     <Typography sx={{ textAlign: 'center' }}>
    //       Drag 'n' drop some files here, or click to select files
    //     </Typography>
    //   </div>
    //   <aside>
    //     <Typography variant="h5" sx={{ textAlign: 'center' }}>
    //       Files
    //     </Typography>
    //     <ul>{files}</ul>
    //   </aside>
    // </Box>
  );
};

const DraftDialog = (props: any) => {
  return (
    <Dialog
      open={props.dialogDraftOpen}
      onClose={() => props.setDialogDraftOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title">
        {"Use Google's location service?"}
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want your post to be saved as draft?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setDialogDraftOpen(false)}>Close</Button>
        <Button onClick={() => props.setDialogDraftOpen(false)} autoFocus>
          Save Draft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IControlDisable {
  chkSuspended: boolean;
  chkDeleteProtect: boolean;
  txtFirstName: boolean;
  txtLastName: boolean;
  txtUsername: boolean;
  txtPassword: boolean;
  txtConfirmPassword: boolean;
  selUserType: boolean;
}

interface IUserPermission {
  chkMayorsOffice: IChkPermission;
  chkLegislative: IChkPermission;
  chkExecutiveOrder: IChkPermission;
  chkOfficeOrder: IChkPermission;
  chkActivities: IChkPermission;
  chkAccountingOffice: IChkPermission;
  chkAgricultureOffice: IChkPermission;
  chkAssessorsOffice: IChkPermission;
  chkBudgetOffice: IChkPermission;
  chkEngineeringOffice: IChkPermission;
  chkDENR: IChkPermission;
  chkHealthOffice: IChkPermission;
  chkLocalCivilRegistrarOffice: IChkPermission;
  chkMarketSlaughterHouse: IChkPermission;
  chkMDRRMO: IChkPermission;
  chkPlanningOffice: IChkPermission;
  chkSocialWelfareDevelopmentOffice: IChkPermission;
  chkTreasurersOffice: IChkPermission;
  chkBFP: IChkPermission;
  chkComelec: IChkPermission;
  chkDAR: IChkPermission;
  chkDILG: IChkPermission;
  chkPOPCOM: IChkPermission;
  chkPNP: IChkPermission;
  chkPostOffice: IChkPermission;
  chkFestival: IChkPermission;
  chkProducts: IChkPermission;
  chkTouristSpots: IChkPermission;
  chkBidsAwards: IChkPermission;
  chkJobs: IChkPermission;
  chkDownloads: IChkPermission;
  chkTransparencySeal: IChkPermission;
  chkTourism: IChkPermission;
  chkLocalOffice: IChkPermission;
  chkNationalOffice: IChkPermission;
  chkGeneral: IChkPermission;
  chkOrdinances: IChkPermission;
  chkResolutions: IChkPermission;
  chkAll: IChkPermission;
  chkMunicipalInformationUnit: IChkPermission;
  chkAllowApprovalOfPost: IChkPermission;
}

interface IChkPermission {
  locked: boolean;
  val: boolean;
  group: string;
}

interface IPasswordCheck {
  upperAndLower: boolean;
  numbers: boolean;
  specialChars: boolean;
  atleast6Chars: boolean;
}

const initPasswordCheck = {
  upperAndLower: false,
  numbers: false,
  specialChars: false,
  atleast6Chars: false,
};

const ChkPermissionsInit = {
  chkMayorsOffice: { val: false, locked: false, group: 'control' },
  chkLegislative: { val: false, locked: false, group: 'control' },
  chkExecutiveOrder: { val: false, locked: false, group: 'mayors' },
  chkOfficeOrder: { val: false, locked: false, group: 'mayors' },
  chkActivities: { val: false, locked: false, group: 'mayors' },
  chkAccountingOffice: { val: false, locked: false, group: 'localOffice' },
  chkAgricultureOffice: { val: false, locked: false, group: 'localOffice' },
  chkAssessorsOffice: { val: false, locked: false, group: 'localOffice' },
  chkBudgetOffice: { val: false, locked: false, group: 'localOffice' },
  chkEngineeringOffice: { val: false, locked: false, group: 'localOffice' },
  chkDENR: { val: false, locked: false, group: 'localOffice' },
  chkHealthOffice: { val: false, locked: false, group: 'localOffice' },
  chkLocalCivilRegistrarOffice: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkMarketSlaughterHouse: { val: false, locked: false, group: 'localOffice' },
  chkMDRRMO: { val: false, locked: false, group: 'localOffice' },
  chkPlanningOffice: { val: false, locked: false, group: 'localOffice' },
  chkSocialWelfareDevelopmentOffice: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkTreasurersOffice: { val: false, locked: false, group: 'localOffice' },
  chkMunicipalInformationUnit: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkRuralHealthUnit: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkOfficeOfTheMunicipalMayor: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkSangguniangBayanOffice: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkHumanResourceOffice: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkTourismOffice: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkPublicEmploymentServiceOffice: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkGeneralServicesOffice: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkInternalAuditService: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkOfficeOfTheMunicipalAdministrator: {
    val: false,
    locked: false,
    group: 'localOffice',
  },
  chkPhilippineCoastGuard: {
    val: false,
    locked: false,
    group: 'nationalOffice',
  },
  chkBFP: { val: false, locked: false, group: 'nationalOffice' },
  chkComelec: { val: false, locked: false, group: 'nationalOffice' },
  chkDAR: { val: false, locked: false, group: 'nationalOffice' },
  chkDILG: { val: false, locked: false, group: 'nationalOffice' },
  chkPOPCOM: { val: false, locked: false, group: 'nationalOffice' },
  chkPNP: { val: false, locked: false, group: 'nationalOffice' },
  chkPostOffice: { val: false, locked: false, group: 'nationalOffice' },
  chkFestival: { val: false, locked: false, group: 'tourism' },
  chkProducts: { val: false, locked: false, group: 'tourism' },
  chkTouristSpots: { val: false, locked: false, group: 'tourism' },
  chkBidsAwards: { val: false, locked: false, group: 'none' },
  chkJobs: { val: false, locked: false, group: 'none' },
  chkDownloads: { val: false, locked: false, group: 'none' },
  chkTransparencySeal: { val: false, locked: false, group: 'none' },
  chkTourism: { val: false, locked: false, group: 'control' },
  chkLocalOffice: { val: false, locked: false, group: 'control' },
  chkNationalOffice: { val: false, locked: false, group: 'control' },
  chkGeneral: { val: false, locked: false, group: 'none' },
  chkOrdinances: { val: false, locked: false, group: 'mayors' },
  chkResolutions: { val: false, locked: false, group: 'mayors' },
  chkAll: { val: false, locked: false, group: 'control' },
  chkAllowApprovalOfPost: { val: false, locked: false, group: 'control' },
};

interface IValidateUser {
  txtFirstName: boolean;
  txtLastName: boolean;
  txtUsername: boolean;
  txtPassword: boolean;
  txtConfirmPassword: boolean;
  selUserType: boolean;
}

const initValidateUser = {
  txtFirstName: false,
  txtLastName: false,
  txtUsername: false,
  txtPassword: false,
  txtConfirmPassword: false,
  selUserType: false,
};

const AddPostDialog = props => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setTableOperation }: { setTableOperation(data: TTableOperation) } =
    props;
  const [user, setUser] = useState(initialUser);
  const { addUserHandler } = props;

  const [switchState, setSwitchState] = React.useState({
    addMultiple: false,
  });

  const [controlDisable, setControlDisable] =
    useState<IControlDisable | null>();

  const [chkPermissions, setChkPermissions] =
    useState<IUserPermission>(ChkPermissionsInit);

  // const [chkAllowApprovalOfPost, setChkAllowApprovalOfPost] =
  //   useState<IChkPermission>({
  //     locked: false,
  //     val: false,
  //     group: '',
  //   });

  const [isDraft, setIsDraft] = useState<Boolean>(false);

  const [isLocked, setIsLocked] = useState<Boolean>(false);

  const [isSuspended, setIsSuspended] = useState<Boolean>(false);

  const [tableOperation, _setTableOperation] =
    React.useState<TTableOperation>('VIEW');
  const [dialogDraftOpen, setDialogDraftOpen] = useState<Boolean>(false);

  const [dpPostDate, setDpPostDate] = useState<Date | null>(null);
  const [txtFirstName, setTxtFirstName] = useState<string>('');
  const [txtLastName, setTxtLastName] = useState<string>('');
  const [txtUsername, setTxtUsername] = useState<string>('');

  const [passwordCheck, setPasswordCheck] =
    useState<IPasswordCheck>(initPasswordCheck);

  const [validateUser, setValidateUser] = useState(initValidateUser);

  const [txtPassword, setTxtPassword] = useState<string>('');
  const [txtConfirmPassword, setTxtConfirmPassword] = useState<string>('');

  const [triggerResetUI, setTriggerResetUI] = useState(false);

  const [UIChangePassword, setUIChangePassword] = useState(false);

  const [selUserType, setSelUserType] = useState<IUserType>(initUserType);

  const [selectedRow, _setSelectedRow] = useState<null | Post>(null);

  const userData = useSelector(
    (state: GlobalReducer) => state.apiData.userData,
  );

  useEffect(() => {
    const {
      selectedRow,
      tableOperation,
    }: { selectedRow: User; tableOperation: TTableOperation } = props;
    if (props.tableOperation === 'VIEW') {
      const userType: any = selectedRow.type ? selectedRow?.type[0] : 1;
      const parsedUserType = userTypes.find(x => x.value === userType);
      const pagePermissions = selectedRow.pagePermissions
        ? selectedRow.pagePermissions
        : [];
      // console.log(userType);
      if (typeof selectedRow !== 'undefined') {
        setTxtFirstName(selectedRow ? `${selectedRow?.name}` : '-');
        setTxtUsername(selectedRow ? `${selectedRow?.username}` : '-');
        setSelUserType(parsedUserType ? parsedUserType : userTypes[1]);
      }

      const tempPermissions = { ...chkPermissions };

      Object.keys(tempPermissions).forEach(key => {
        if (pagePermissions.includes(key.slice(3)))
          tempPermissions[key] = { ...tempPermissions[key], val: true };
      });
      // setChkPermissions(tempPermissions);
      //console.log(tempPermissions);
      toggleControlDisable(true, tempPermissions);
      setUIChangePassword(false);
    }
    //console.log(selectedRow);
    _setTableOperation(tableOperation);

    // setIsDraft(Boolean(selectedRow.isDraft));
    _setSelectedRow(selectedRow);
    //@ts-ignore

    // dispatch(apiStoreImageDetails(selectedRow.imageDescription));
  }, [props.selectedRow, props.tableOperation]);

  useEffect(() => {
    resetDialog();
    setIsLocked(true);
    setDpPostDate(moment().toDate());
  }, [triggerResetUI]);

  const dispatch = useDispatch();

  const toggleControlDisable = (
    toggle: boolean,
    _chkPermission?: IUserPermission,
  ) => {
    //setControlDisable(null);
    setControlDisable({
      chkSuspended: toggle,
      chkDeleteProtect: toggle,
      txtFirstName: toggle,
      txtLastName: toggle,
      txtUsername: toggle,
      txtPassword: toggle,
      txtConfirmPassword: toggle,
      selUserType: toggle,
    });
    let objA = _chkPermission ? { ..._chkPermission } : { ...chkPermissions };
    Object.keys(objA).forEach(key => {
      objA[key] = { ...objA[key], locked: toggle };
    });

    setChkPermissions(objA);
    //console.log(controlDisable);
  };

  const resetDialog = () => {
    // toggleControlDisable(false);
    resetSwitch();
    setIsDraft(false);
    setChkPermissions(ChkPermissionsInit);
    setTxtFirstName('');
    setTxtLastName('');
    setTxtUsername('');
    setTxtPassword('');
    setTxtConfirmPassword('');
    setIsSuspended(false);
    setIsLocked(true);
    //setUIChangePassword(false);
  };

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    props.setDialogAddPostOpen(true);
  };

  const handleClose = () => {
    props.setDialogAddPostOpen(false);
    if (!isDraft) setDialogDraftOpen(false);

    //resetDialog();
  };

  const handleAdd = () => {
    setTableOperation('ADD');
    setTriggerResetUI(triggerResetUI ? false : true);
    toggleControlDisable(false);
    setSelUserType(userTypes[1]);
    props.setDialogAddPostOpen(true);
    setUIChangePassword(true);
  };

  const handleModify = () => {
    setTableOperation('EDIT');
    toggleControlDisable(false);
  };
  const handleModifySave = () => {
    validateUserInputs(() => {
      let permissions: string[] = [];
      let objA = { ...chkPermissions };
      Object.keys(objA).forEach(key => {
        if (objA[key].val) {
          permissions.push(key.slice(3, key.length));
        }
      });

      const userTypeId: number = selUserType ? selUserType.value : 1;

      const userData: User = {
        id: selectedRow?.id,
        isLocked: Number(isLocked),
        // isDraft: Number(isDraft),
        pagePermissions: permissions,
        name: `${txtLastName} ${txtFirstName}`,

        ...(UIChangePassword ? { password: txtPassword } : {}),
        username: txtUsername,
        types: [userTypeId],
        datePosted: Misc.parseAPIDateTime(dpPostDate),

        //files: imgFiles,
      };

      //console.log('PRE-POST UPDATE DATA ====>', userData);

      userAPI.updateUser(dispatch, userData, () => {
        enqueueSnackbar('User Successfully Modified', { variant: 'success' });
        setTableOperation('REFRESH');
        handleClose();
      });
    });
  };
  const handleDelete = () => {
    const userTypeId: number = selUserType ? selUserType.value : 1;

    if (userTypeId === 0) {
      dispatch(
        UIToggleGlobalModal({
          title: 'Action not allowed',
          description: (
            <>
              <Typography>
                You cannot remove admin user type. Please contact administrator.
              </Typography>
            </>
          ),

          isOpen: true,
          isYesNo: true,
        }),
      );
    } else {
      setTableOperation('DELETE');
    }
  };
  const handleDeleteSave = () => {

    userAPI.deleteUser(dispatch, Number(selectedRow?.id), () => {
      enqueueSnackbar('User Successfully Removed', { variant: 'success' });
      setTableOperation('REFRESH');
      handleClose();
    });
  };
  const handleCancel = () => {
    setTableOperation('VIEW');
    setUIChangePassword(false);
  };
  const handleAddSave = event => {
    //addUserHandler(user);
    //setUser(initialUser);

    validateUserInputs(() => {
      switchState.addMultiple
        ? props.setDialogAddPostOpen(true)
        : props.setDialogAddPostOpen(false);

      let permissions: string[] = [];
      let objA = { ...chkPermissions };
      Object.keys(objA).forEach(key => {
        if (objA[key].val) {
          permissions.push(key.slice(3, key.length));
        }
      });

      const userTypeId: number = selUserType ? selUserType.value : 1;

      const userData: User = {
        isLocked: Number(isLocked),
        // isDraft: Number(isDraft),
        pagePermissions: permissions,
        name: `${txtLastName} ${txtFirstName}`,
        password: txtPassword,
        username: txtUsername,
        types: [userTypeId],
        datePosted: Misc.parseAPIDateTime(dpPostDate),

        //files: imgFiles,
      };
      //console.log('PRE-POST DATA ====>', userData);

      userAPI.createUser(dispatch, userData, () => {
        enqueueSnackbar('User Successfully Added', { variant: 'success' });
        setTableOperation('REFRESH');
        handleClose();
      });
    });
  };

  const handleChange =
    name =>
    ({ target: { value } }) => {
      setUser({ ...user, [name]: value });
    };

  const customSelStyles = {
    menu: (provided, state) => ({
      ...provided,
      // width: state.selectProps.width,
      // borderBottom: '1px dotted pink',
      // color: state.selectProps.menuColor,
      // padding: 20,

      zIndex: 500,
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      height: '55px',
      marginTop: 7,
      display: 'flex',
    }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    //   return { ...provided, opacity, transition };
    // },
  };
  useEffect(() => {
    let tempPasswordCheck = { ...passwordCheck };
    const upperAndLower = /(?=.*[a-z])(?=.*[A-Z])/;
    const numbers = /(?=.*\d)/;
    const specialChars = /(?=.*\W)/;
    const testStr = txtPassword ? txtPassword : '';
    upperAndLower.test(testStr)
      ? (tempPasswordCheck.upperAndLower = true)
      : (tempPasswordCheck.upperAndLower = false);
    numbers.test(testStr)
      ? (tempPasswordCheck.numbers = true)
      : (tempPasswordCheck.numbers = false);
    specialChars.test(testStr)
      ? (tempPasswordCheck.specialChars = true)
      : (tempPasswordCheck.specialChars = false);
    testStr.length >= 6
      ? (tempPasswordCheck.atleast6Chars = true)
      : (tempPasswordCheck.atleast6Chars = false);
    //console.log(tempPasswordCheck);
    setPasswordCheck(tempPasswordCheck);
  }, [txtPassword]);

  const validateUserInputs = (cb: VoidFunction) => {
    let tempUserInput = { ...validateUser };
    let error = false;

    txtFirstName?.length > 0
      ? (tempUserInput.txtFirstName = false)
      : (tempUserInput.txtFirstName = true);
    // txtLastName?.length > 0
    //   ? (tempUserInput.txtLastName = false)
    //   : (tempUserInput.txtLastName = true);
    txtUsername?.length > 0
      ? (tempUserInput.txtUsername = false)
      : (tempUserInput.txtUsername = true);

    if (UIChangePassword) {
      txtPassword?.length > 0
        ? (tempUserInput.txtPassword = false)
        : (tempUserInput.txtPassword = true);
      txtConfirmPassword?.length > 0
        ? (tempUserInput.txtConfirmPassword = false)
        : (tempUserInput.txtConfirmPassword = true);
      txtConfirmPassword === txtPassword
        ? (tempUserInput.txtConfirmPassword = false)
        : (tempUserInput.txtConfirmPassword = true);
    }

    selUserType
      ? (tempUserInput.selUserType = false)
      : (tempUserInput.selUserType = true);
    setValidateUser(tempUserInput);
    Object.keys(tempUserInput).forEach(key => {
      if (tempUserInput[key]) {
        error = true;
      }
    });
    if (error) enqueueSnackbar('Check Inputs', { variant: 'error' });
    else if (cb) cb();

    // console.log(tempUserInput);
  };

  useEffect(() => {
    let objA = { ...chkPermissions };
    Object.keys(objA).forEach(key => {
      if (objA[key].group !== 'control')
        objA[key] = { ...objA[key], val: chkPermissions.chkAll.val };
    });
    setChkPermissions(objA);
  }, [chkPermissions.chkAll.val]);
  useEffect(() => {
    let objA = { ...chkPermissions };
    Object.keys(objA).forEach(key => {
      if (objA[key].group === 'tourism')
        objA[key] = { ...objA[key], val: chkPermissions.chkTourism.val };
    });
    setChkPermissions(objA);
  }, [chkPermissions.chkTourism.val]);
  useEffect(() => {
    let objA = { ...chkPermissions };
    Object.keys(objA).forEach(key => {
      if (objA[key].group === 'nationalOffice')
        objA[key] = { ...objA[key], val: chkPermissions.chkNationalOffice.val };
    });
    setChkPermissions(objA);
  }, [chkPermissions.chkNationalOffice.val]);
  useEffect(() => {
    let objA = { ...chkPermissions };
    Object.keys(objA).forEach(key => {
      if (objA[key].group === 'localOffice')
        objA[key] = { ...objA[key], val: chkPermissions.chkLocalOffice.val };
    });
    setChkPermissions(objA);
  }, [chkPermissions.chkLocalOffice.val]);
  useEffect(() => {
    let objA = { ...chkPermissions };
    Object.keys(objA).forEach(key => {
      if (objA[key].group === 'mayors')
        objA[key] = { ...objA[key], val: chkPermissions.chkMayorsOffice.val };
    });
    setChkPermissions(objA);
  }, [chkPermissions.chkMayorsOffice.val]);

  const UserGroupPermissions = () => {
    const AllGroup = [];

    return (
      <Grid container>
        <Grid item>
          {groupPagePermissions.map((val, index) => {
            const component: IChkPermission =
              chkPermissions['chk' + val.componentName];
            return (
              <FormControlLabel
                key={index}
                sx={{ width: '100%' }}
                control={
                  <Switch
                    disabled={component?.locked}
                    checked={Boolean(component?.val)}
                    onChange={() => {
                      component?.val
                        ? setChkPermissions({
                            ...chkPermissions,
                            //@ts-ignore
                            ['chk' + val.componentName]: {
                              ...component,
                              val: false,
                            },
                          })
                        : setChkPermissions({
                            ...chkPermissions,
                            //@ts-ignore
                            ['chk' + val.componentName]: {
                              ...component,
                              val: true,
                            },
                          });
                    }}
                  />
                }
                label={val.n}
              />
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const UserPermissions = () => {
    const toRemove = new Set([
      'Search',
      'Announcements',
      'Posts',
      'MayorsOffice',
      'Legislative',
    ]);

    const difference = specialPages.filter(x => !toRemove.has(x.componentName));

    return (
      <Grid container>
        <Grid item xs={6}>
          {difference.map((val, index) => {
            const component: IChkPermission =
              chkPermissions['chk' + val.componentName];
            if ((index + 1) % 2 === 0) {
              return (
                <FormControlLabel
                  key={index}
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={component?.locked}
                      checked={Boolean(component?.val)}
                      onChange={() => {
                        component?.val
                          ? setChkPermissions(e => {
                              return {
                                ...e,
                                //@ts-ignore
                                ['chk' + val.componentName]: {
                                  ...component,
                                  val: false,
                                },
                              };
                            })
                          : setChkPermissions(e => {
                              return {
                                ...e,
                                //@ts-ignore
                                ['chk' + val.componentName]: {
                                  ...component,
                                  val: true,
                                },
                              };
                            });
                      }}
                    />
                  }
                  label={val.n}
                />
              );
            } else {
              return null;
            }
          })}
        </Grid>
        <Grid item xs={6}>
          {difference.map((val, index) => {
            const component: IChkPermission =
              chkPermissions['chk' + val.componentName];

            if ((index + 1) % 2 === 1) {
              return (
                <FormControlLabel
                  key={index}
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={component?.locked}
                      checked={Boolean(component?.val)}
                      onChange={() => {
                        component?.val
                          ? setChkPermissions(e => {
                              return {
                                ...e,
                                //@ts-ignore
                                ['chk' + val.componentName]: {
                                  ...component,
                                  val: false,
                                },
                              };
                            })
                          : setChkPermissions(e => {
                              return {
                                ...e,
                                //@ts-ignore
                                ['chk' + val.componentName]: {
                                  ...component,
                                  val: true,
                                },
                              };
                            });
                      }}
                    />
                  }
                  label={val.n}
                />
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Tooltip title="Add">
        {/* <IconButton
          aria-label="add"
          onClick={() => {
            handleAdd();
          }}
        >
          <AddCircle />
          Add
        </IconButton> */}
        <Button
          aria-label="add"
          variant="contained"
          disableElevation
          onClick={() => {
            handleAdd();
          }}
        >
          Add User
        </Button>
      </Tooltip>
      <DraftDialog
        dialogDraftOpen={dialogDraftOpen}
        setDialogDraftOpen={setDialogDraftOpen}
      />
      <Dialog
        open={props.dialogAddPostOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        //fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {tableOperation} User
            </Typography>
            {tableOperation === 'ADD' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'success.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleAddSave}
                >
                  Save
                </Button>
              </>
            ) : null}
            {tableOperation === 'VIEW' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'info.main',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={() => {
                    handleModify();
                  }}
                >
                  Modify
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'error.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </>
            ) : null}
            {tableOperation === 'EDIT' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'success.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleModifySave}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'warning.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : null}
            {tableOperation === 'DELETE' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'error.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleDeleteSave}
                >
                  Remove
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'warning.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : null}
          </Toolbar>
        </AppBar>
        {/* <DialogTitle id="form-dialog-title">Add Post</DialogTitle> */}
        <DialogContent sx={{ m: 0, p: 0 }}>
          {/* <Paper
            variant="elevation"
            square
            elevation={3}
            sx={{ backgroundColor: 'blue', m: 0, p: 1 }}
          >
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              This post is saved as draft
            </Typography>
          </Paper> */}
          <Box sx={{ p: 3.5 }}>
            {/* <FormControlLabel
              sx={{ width: '100%' }}
              control={
                <Switch
                  checked={Boolean(isAnnouncement)}
                  onChange={() => {
                    isAnnouncement
                      ? setIsAnnouncement(false)
                      : setIsAnnouncement(true);
                  }}
                />
              }
              label="Announcement"
            /> */}
            <Grid container>
              {/* <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkImgPost}
                      checked={Boolean(isImageOnly)}
                      onChange={() => {
                        isImageOnly
                          ? setIsImageOnly(false)
                          : setIsImageOnly(true);
                      }}
                    />
                  }
                  label="Image Post Only"
                />
              </Grid> */}
              <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkDeleteProtect}
                      checked={Boolean(isLocked)}
                      onChange={() => {
                        isLocked ? setIsLocked(false) : setIsLocked(true);
                      }}
                    />
                  }
                  label="Delete Protect"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkSuspended}
                      checked={Boolean(isSuspended)}
                      onChange={() => {
                        isSuspended
                          ? setIsSuspended(false)
                          : setIsSuspended(true);
                      }}
                    />
                  }
                  label="Suspend User"
                />
              </Grid>
              {/* <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkHiddenPost}
                      checked={Boolean(isHidden)}
                      onChange={() => {
                        isHidden ? setIsHidden(false) : setIsHidden(true);
                      }}
                    />
                  }
                  label="Hidden Post"
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkPinned}
                      checked={Boolean(isPinned)}
                      onChange={() => {
                        isPinned ? setIsPinned(false) : setIsPinned(true);
                      }}
                    />
                  }
                  label="Pinned"
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkArchived}
                      checked={Boolean(isArchived)}
                      onChange={() => {
                        isArchived ? setIsArchived(false) : setIsArchived(true);
                      }}
                    />
                  }
                  label="Archived"
                />
              </Grid> */}
            </Grid>
            <Grid spacing={2} container>
              <Grid xs item>
                <TextField
                  error={validateUser.txtFirstName}
                  label="Name"
                  sx={{ my: '7.5px' }}
                  value={txtFirstName}
                  onChange={e => setTxtFirstName(e.target.value)}
                  type="text"
                  fullWidth
                  disabled={controlDisable?.txtFirstName}
                />
              </Grid>
              <Grid xs item>
                {/* <TextField
                  error={validateUser.txtLastName}
                  label="Last Name"
                  sx={{ my: '7.5px' }}
                  value={txtLastName}
                  onChange={e => setTxtLastName(e.target.value)}
                  type="text"
                  fullWidth
                  disabled={controlDisable?.txtLastName}
                /> */}
              </Grid>
            </Grid>
            <Grid spacing={2} container>
              <Grid xs item>
                <TextField
                  error={validateUser.txtUsername}
                  label="Username"
                  sx={{ my: '7.5px' }}
                  value={txtUsername}
                  onChange={e => setTxtUsername(e.target.value)}
                  type="text"
                  fullWidth
                  disabled={controlDisable?.txtUsername}
                />
              </Grid>
              <Grid xs item></Grid>
            </Grid>
            {UIChangePassword ? (
              <Paper sx={{ p: 2 }} variant="outlined">
                <Grid spacing={2} container>
                  <Grid xs item>
                    <TextField
                      error={validateUser.txtPassword}
                      label="Password"
                      sx={{ my: '7.5px' }}
                      value={txtPassword}
                      onChange={e => setTxtPassword(e.target.value)}
                      type="text"
                      fullWidth
                      disabled={controlDisable?.txtPassword}
                    />
                  </Grid>
                  <Grid xs item>
                    <TextField
                      error={validateUser.txtConfirmPassword}
                      label="Confirm Password"
                      sx={{ my: '7.5px' }}
                      value={txtConfirmPassword}
                      onChange={e => setTxtConfirmPassword(e.target.value)}
                      type="text"
                      fullWidth
                      disabled={controlDisable?.txtConfirmPassword}
                      helperText={
                        validateUser.txtConfirmPassword
                          ? 'Password does not match'
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
                <Typography sx={{ my: '7.5px' }}>
                  Password should contain:
                </Typography>
                <FormGroup
                  sx={{
                    '& .Mui-disabled': { color: 'black.0.0 !important' },
                  }}
                >
                  <FormControlLabel
                    disabled
                    control={<Checkbox />}
                    label="Uppercase and Lowercase Letters"
                    checked={passwordCheck.upperAndLower}
                  />
                  <FormControlLabel
                    disabled
                    control={<Checkbox />}
                    label="Numbers"
                    checked={passwordCheck.numbers}
                  />
                  <FormControlLabel
                    disabled
                    control={<Checkbox />}
                    label="Special Characters"
                    checked={passwordCheck.specialChars}
                  />
                  <FormControlLabel
                    disabled
                    control={<Checkbox />}
                    label="Atleast 6 characters"
                    checked={passwordCheck.atleast6Chars}
                  />
                </FormGroup>
              </Paper>
            ) : (
              <Button
                variant="contained"
                disabled={controlDisable?.txtFirstName}
                onClick={() => {
                  setUIChangePassword(true);
                }}
              >
                Change Password
              </Button>
            )}

            <Typography sx={{ my: '7.5px' }}>User Type</Typography>
            <Select
              error={validateUser.selUserType}
              styles={customSelStyles}
              options={userTypes}
              isDisabled={controlDisable?.selUserType}
              //@ts-ignore
              getOptionValue={item => item.value}
              getOptionLabel={item => item.name}
              value={selUserType}
              onChange={e => setSelUserType(e ? e : userTypes[1])}
            />
            <Typography sx={{ my: '7.5px' }}>Special Permissions</Typography>
            <FormControlLabel
              sx={{ width: '100%' }}
              name="formChkPermissions"
              control={
                <Switch
                  disabled={chkPermissions.chkAllowApprovalOfPost.locked}
                  checked={Boolean(chkPermissions.chkAllowApprovalOfPost.val)}
                  onChange={() => {
                    chkPermissions.chkAllowApprovalOfPost.val
                      ? setChkPermissions(e => {
                          return {
                            ...e,
                            //@ts-ignore
                            chkAllowApprovalOfPost: {
                              ...e.chkAllowApprovalOfPost,
                              val: false,
                            },
                          };
                        })
                      : setChkPermissions(e => {
                          return {
                            ...e,
                            //@ts-ignore
                            chkAllowApprovalOfPost: {
                              ...e.chkAllowApprovalOfPost,
                              val: true,
                            },
                          };
                        });
                  }}
                />
              }
              label="Allow approval of pending post"
            />
            <Typography sx={{ my: '7.5px' }}>Group Permissions</Typography>
            <UserGroupPermissions />
            <Typography sx={{ my: '7.5px' }}>Individual Permissions</Typography>
            <UserPermissions />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddPostDialog;
