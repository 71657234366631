import { apiRequest } from 'actions/actionCreator';
import { Post } from 'types/post';
import { Filter } from 'types/filter';
class ContentAPI {
  getFile = async (
    dispatch: any,
    uniqueID: string,
    filename: string,
    cb?: any,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Posts from server',
          `global/getFile?uniqueId=${uniqueID}&filename=${filename}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getPosts = async (dispatch: any, filter: Filter, cb?: any) => {
    let filterData: string[] = [];
    if (filter.isAnnouncement !== undefined)
      filterData.push(`isAnnouncement=${filter.isAnnouncement}`);
    if (filter.isArchived !== undefined)
      filterData.push(`isArchived=${filter.isArchived}`);
    if (filter.uniqueLink !== undefined)
      filterData.push(`uniqueLink=${filter.uniqueLink}`);
    if (filter.postType !== undefined)
      filterData.push(`postType=${filter.postType}`);
    if (filter.limit !== undefined) filterData.push(`limit=${filter.limit}`);

    const queryString = filterData.length > 0 ? `?${filterData.join('&')}` : '';

    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          {},
          'Fetching Posts from server',
          `posts/cmsGetPosts/${queryString}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        return [];
      },
    );

    if (cb) await cb();
    return data;
  };
  getAllUnfilteredPost = async (dispatch: any, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Posts from server',
          'posts/getAllUnfilteredPost',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getAllPosts = async (dispatch: any, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Posts from server',
          'posts/getPosts',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getAllAnnouncements = async (dispatch: any, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Announcements from server',
          'posts/getAnnouncements',
          resolve,
          reject,
        ),
      );
    })
      .then(
        async (onfulfilled: any) => {
          return onfulfilled.data;
        },
        (onrejected: any) => {
          //(onrejected);
          return [];
          //this.handleDropCancel();
        },
      )
      .finally(() => {
        if (cb) cb();
      });

    return data;
  };
  getPostById = async (dispatch: any, id: string, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          id,
          'Fetching Post Content',
          `posts/getPost?id=${Number(id)}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  createPost = async (
    dispatch: any,
    postData: Post,
    files: File[],
    delay?: number,
    cb?: VoidFunction,
  ) => {
    const postFormData = new FormData();
    for (const key of Object.keys(postData)) {
      postFormData.append(key, postData[key]);
    }
    if (files.length > 0) {
      for (let val = 0; val !== files.length; val++) {
        //console.log(files[val]);
        postFormData.append('files', files[val]);
      }
    }
    //postFormData.append('files',files);

    //@ts-ignore
    for (var pair of postFormData.entries()) {
      //console.log(pair[0] + ', ' + pair[1]);
    }
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'post',
          postFormData,
          'Creating Post',
          'posts/createPost',
          resolve,
          reject,
          delay,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) cb();
    return data;
  };
  updatePost = async (
    dispatch: any,
    postData: Post,
    files: File[],
    delay?: number,
    cb?: VoidFunction,
  ) => {
    const postFormData = new FormData();
    for (const key of Object.keys(postData)) {
      postFormData.append(key, postData[key]);
    }
    if (files.length > 0) {
      for (let val = 0; val !== files.length; val++) {
        postFormData.append('files', files[val]);
      }
    }

    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'post',
          postFormData,
          'Updating Post',
          'posts/updatePost',
          resolve,
          reject,
          delay,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  submitPostForVerification = async (
    dispatch: any,
    postId: number | undefined,
    delay?: number,
    cb?: VoidFunction,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'post',
          { id: postId },
          'Updating Post',
          'posts/submitPostForVerification',
          resolve,
          reject,
          delay,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  deletePost = async (dispatch: any, postId: number, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'delete',
          postId,
          'Removing Post',
          'posts/deletePost',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
}
export default new ContentAPI();
