import moment from 'moment';
class Misc {
  dateToday(data) {
    if (data) {
      return moment(data).format('MMMM Do YYYY');
    } else {
      return moment().format('MMMM Do YYYY');
    }
  }
  parseAPIDateTime(data) {
    return moment(moment(data).format('YYYY-MM-DD HH:mm:ss')).format('LL');
  }
  parseAPIDateTimeFull(data) {
    if (data) {
      return moment(moment(data).format('YYYY-MM-DD HH:mm:ss')).format('LLLL');
    } else {
      return '-';
    }
  }
  parseAPIDate(data) {
    return moment(data).format('YYYY-MM-DD');
  }
  parseAPIDateTimeToShortDate(data) {
    return moment(data).format('llll');
  }
}
export default new Misc();
