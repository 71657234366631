import { apiRequest } from 'actions/actionCreator';
import { Post } from 'types/post';
import { Filter } from 'types/filter';
import { Dispatch } from '@reduxjs/toolkit';
import { IAuthenticate } from 'types/user';
class AuthenticationAPI {
  authenticate = async (
    dispatch: Dispatch,
    userData: IAuthenticate,

    cb?: VoidFunction,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'post',
          userData,
          'Authenticating',
          'auth/login',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  reAuthenticate = async (
    dispatch: Dispatch,
    token: string,

    cb?: VoidFunction,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'post',
          { sessionID: token },
          'Re-Authenticating',
          'auth/reAuthenticate',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        //console.log(onfulfilled);
        return onfulfilled;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
}
export default new AuthenticationAPI();
