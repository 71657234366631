import { apiRequest } from 'actions/actionCreator';
import { Post } from 'types/post';
import { Filter } from 'types/filter';
import { Dispatch } from '@reduxjs/toolkit';
import { User } from 'types/user';
class UserAPI {
  getAllActors = async (dispatch: Dispatch, cb?: VoidFunction) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest('get', null, 'Fetching Users', 'actor/', resolve, reject),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  createUser = async (
    dispatch: Dispatch,
    userData: User,

    cb?: VoidFunction,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'post',
          userData,
          'Creating User',
          'actor/',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  updateUser = async (
    dispatch: any,
    userData: User,
    //files: File[],
    cb?: any,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'put',
          userData,
          'Updating Actor',
          'actor/updateActor',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  deleteUser = async (dispatch: any, userId: number, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'delete',
          userId,
          'Removing User',
          'actor/deleteActor',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
}
export default new UserAPI();
