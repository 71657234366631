import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material';
import { UIToggleGlobalModal } from 'actions/actionCreator';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalReducer } from 'types/globalReducer';

export default function GlobalDialog() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state: GlobalReducer) => state.globalModal.isOpen,
  );
  const description = useSelector(
    (state: GlobalReducer) => state.globalModal.description,
  );
  const title = useSelector((state: GlobalReducer) => {
    return state.globalModal.title;
  });
  const isYesNo = useSelector((state: GlobalReducer) => {
    return state.globalModal.isYesNo;
  });
  const cb = useSelector((state: GlobalReducer) => {
    return state.globalModal.cb;
  });
  const cbNo = useSelector((state: GlobalReducer) => {
    return state.globalModal.cbNo;
  });

  const handleClickOpen = () => {
    UIToggleGlobalModal({ title: '', description: <></>, isOpen: true });
  };

  const handleClose = () => {
    dispatch(
      UIToggleGlobalModal({ title: '', description: <></>, isOpen: true }),
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {/* <Typography id="alert-dialog-description">{description}</Typography> */}
        {description}
      </DialogContent>
      {isYesNo ? (
        <DialogActions>
          <Button
            onClick={() => {
              if (cbNo) {
                cbNo();
              }
              handleClose();
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              if (cb) {
                cb();
                handleClose();
              }
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
