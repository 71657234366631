import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import {
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Post } from 'types/post';
import { Image } from 'mui-image';
//import { Editor } from 'react-draft-wysiwyg';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ContentAPI from '../../../api/contentAPI';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Misc from '../../../functions/misc';
export const PostPage = lazyLoad(
  () => import('./PostPage'),
  module => module.PostPageContent,
);
const generateImgs = (parsedFiles, post: Post | undefined) => {
  // console.log(parsedFiles, post);

  const imageDescription = post?.imageDescription ? post?.imageDescription : [];

  const imagesJSX = parsedFiles.map((val: any, index) => {
    let imageLabel = '';
    if (imageDescription.length > 0) {
      const imageDesc = imageDescription.find(
        _val => _val.fileName === val.originalname,
      );

      imageLabel = imageDesc?.description;
    }
    return (
      <Box key={index} sx={{}}>
        <Image
          src={`${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${post?.uniqueId}&filename=${val.filename}`}
          duration={0}
          fit="cover"
          // height="750px"
        />

        <Typography className="legend" sx={{ width: '100rem' }}>
          {imageLabel}
        </Typography>
      </Box>
    );
  });

  return imagesJSX;
};

const SkeletonCont = () => {
  return (
    <Paper variant="outlined" sx={{ px: '12px', py: '7.5px', my: '7.5px' }}>
      <Skeleton variant="text" width="50%" animation="wave" />
      <Skeleton
        variant="text"
        width="50%"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton
        variant="rectangular"
        height="250px"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton variant="text" width="60%" animation="wave" />
    </Paper>
  );
};

export function PostPageContent(props: any) {
  let params = useParams();

  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [contentState, setContentState] = useState<any>(null);
  const [post, setPost] = useState<Post>();
  const [imgFiles, setImgFiles] = useState<File[]>();
  const [JSXimgFiles, setJSXImgFiles] = useState<any>(null);

  // const findPostByID = (id: number) => {
  //   const _data = postData.find(item => item.id === id);
  //   setContentState(_data?.postContent);
  //   return _data;
  // };

  const onContentStateChange = contentState => {
    setContentState(contentState);
  };

  useEffect(() => {
    async function getPost() {
      //console.log(params.postId);
      const data: Post = await ContentAPI.getPostById(
        dispatch,
        params.postId ? params.postId : '0',
      );
      setPost(data);

      if (data.files !== '' || data.files !== null) {
        const parsedFiles = JSON.parse(data.files);
        //console.log(parsedFiles);
        setImgFiles(parsedFiles);
        setJSXImgFiles(generateImgs(parsedFiles, data));
      }

      //console.log(data);
    }
    getPost();
    //@ts-ignore

    //setPost(findPostByID(Number(params.postId)));
    //console.log(findPostByID(Number(params.postId)));
  }, [params.postId]);

  const ImgCarousel = () => {
    if (JSXimgFiles !== null) {
      return (
        <Box
          sx={{
            // backgroundColor: 'primary.main',

            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              '& .carousel-status': { fontSize: '1.2rem', pr: '12px' },
              '& .legend': {
                borderRadius: '0px !important',
                opacity: '1 !important',
                ml: '-50% !important',
                width: '100% !important',
                bottom: '0px !important',
                backgroundColor: 'hsla(0,0%,4%,.5) !important',
                // height: '25px',
                p: '1rem !important',
                fontSize: '16px !important',
                textAlign: 'left !important',
                zIndex: '100 !important',
              },

              // maxHeight: '750px',
              // width: '100rem',
              alignSelf: 'center',
            }}
          >
            <Carousel
              interval={2000}
              infiniteLoop
              //autoPlay
              showThumbs={false}
              showIndicators={false}
              swipeable={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <Button
                    disableRipple
                    sx={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      left: 15,
                      '& :hover': { backgroundColor: 'none' },
                    }}
                    onClick={onClickHandler}
                  >
                    <KeyboardArrowLeft
                      sx={{
                        fontSize: '6rem',
                        color: 'white',
                        backgroundColor: 'primary.main',
                        opacity: '0.15',
                      }}
                    />
                  </Button>
                )
              }
              renderArrowNext={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <Button
                    disableRipple
                    sx={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      right: 15,
                      '& :hover': { backgroundColor: 'none' },
                    }}
                    onClick={onClickHandler}
                  >
                    <KeyboardArrowRight
                      sx={{
                        fontSize: '6rem',
                        color: 'white',
                        backgroundColor: 'primary.main',
                        opacity: '0.15',
                      }}
                    />
                  </Button>
                )
              }
            >
              {JSXimgFiles}
            </Carousel>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Post</title>
        <meta name="description" content="Post" />
      </Helmet>
      <Box
        sx={{
          p: 5,
          height: '100%',
          '@media (max-width: 1330px)': {
            // p: '15px',
          },
        }}
      >
        <Grid container>
          <Grid xs={12} sm={12} md={6} lg={6} item>
            <Typography
              sx={{
                '@media (max-width: 1330px)': {
                  textAlign: 'center',
                },
              }}
              variant="h3"
            >
              Post
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{ textAlign: 'right', alignSelf: 'center' }}
            item
          >
            <Box
              sx={{
                '@media (max-width: 1330px)': {
                  justifyContent: 'center',
                },
                '@media (min-width: 1330px)': {
                  justifyContent: 'right',
                },
                display: 'flex',
              }}
            >
              <Typography sx={{ pr: '10px' }}>Author Admin</Typography>

              <Divider orientation="vertical" flexItem />
              <Typography sx={{ px: '10px' }}>Likes:</Typography>
              <Divider orientation="vertical" flexItem />
              <Typography sx={{ px: '10px' }}>
                {Misc.parseAPIDateTime(post?.datePosted)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider />
        <Box sx={{ py: '15px' }}>
          <Typography
            variant="h4"
            sx={{
              my: '7.5px',
              '@media (max-width: 1330px)': {
                textAlign: 'center',
              },
              '@media (min-width: 1330px)': {},
            }}
          >
            {post?.title}
          </Typography>
          {/* {typeof post?.postBanner === 'string' ? (
            post?.postBanner !== '' ? (
              <Box sx={{ my: '15px' }}>
                <Image
                  src={require(`../../../assets/uploads/${post.}`)}
                  duration={0}
                  fit="contain"
                  height="40rem"
                />
              </Box>
            ) : null
          ) : null} */}

          {imgFiles !== undefined && imgFiles !== null ? (
            imgFiles.length > 0 ? (
              <ImgCarousel />
            ) : null
          ) : null}
          <Box
            sx={{
              mt: '15px',
              maxWidth: '100vw !important',
            }}
          >
            {post?.postContent !== null && post?.postContent !== '' ? (
              <ReactQuill
                modules={{ toolbar: false }}
                theme="snow"
                readOnly
                value={post?.postContent}
                onChange={setValue}
              />
            ) : (
              <>
                {post?.postContent !== null && post?.postContent !== '' ? (
                  <SkeletonCont />
                ) : null}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
