import { combineReducers } from 'redux';
import socketIOClient from 'socket.io-client';
let socket = false;
const apiDataInitialState = {
  userData: [],
  isAuth: false,
};

const navDataInitialState = {
  announcements: [],
  isAnnouncementsLoading: false,
  posts: [],
  isPostsLoading: false,
  selectedNav: '',
  executiveOrders: [],
  resolutions: [],
  ordinances: [],
};
const globalDocViewerInitialState = {
  isOpen: false,
  docFile: null,
  fileName: '',
  uniqueID: '',
};

const GlobalModalInitialState = {
  title: '',
  description: '',
  isOpen: false,
  isYesNo: false,
  cb: null,
  cbNo: null,
};
const ImageDetailsDilogInitialState = {
  imageDetails: '[]',
};

const sessionAuthInitialState = {
  timeRemind: null,
  timeLogout: null,
};
function sessionAuth(state = sessionAuthInitialState, action) {
  switch (action.type) {
    case 'UI_SET_SESSION_AUTH':
      return Object.assign({}, state, {
        ...state,
        timeRemind: action.payload.timeRemind,
        timeLogout: action.payload.timeLogout,
      });
    case 'UI_CLEAR_SESSION_AUTH':
      clearInterval(state.timeLogout);
      clearInterval(state.timeRemind);
      return Object.assign({}, state, {
        ...state,
        timeRemind: null,
        timeLogout: null,
      });

    default:
      return state;
  }
}
function imageDetailDialogData(state = ImageDetailsDilogInitialState, action) {
  switch (action.type) {
    case 'API_STORE_IMAGE_DETAILS':
      return Object.assign({}, state, {
        ...state,
        imageDetails: action.payload.imageDetail,
      });

    default:
      return state;
  }
}
function globalModal(state = GlobalModalInitialState, action) {
  switch (action.type) {
    case 'TOGGLE_GLOBAL_MODAL':
      return Object.assign({}, state, {
        ...state,
        isOpen: state.isOpen ? false : true,
        title: action.payload.title,
        description: action.payload.description,
        isYesNo: action.payload.isYesNo,
        cb: action.payload.cb,
        cbNo: action.payload.cbNo,
      });

    default:
      return state;
  }
}

function globalDocViewer(state = globalDocViewerInitialState, action) {
  switch (action.type) {
    case 'UI_TOGGLE_DOC_VIEWER':
      return Object.assign({}, state, {
        ...state,
        docFile: action.payload.docFile,
        isOpen: action.payload.data,
      });
    case 'UI_TOGGLE_DOC_VIEWER_WDATA':
      return Object.assign({}, state, {
        ...state,
        docFile: action.payload.docFile,
        isOpen: action.payload.data,
        fileName: action.payload.fileName,
        uniqueID: action.payload.uniqueID,
      });
    case 'UI_STORE_DOC':
      return Object.assign({}, state, {
        ...state,

        docFile: action.payload.data,
      });
    default:
      return state;
  }
}

function navData(state = navDataInitialState, action) {
  switch (action.type) {
    case 'API_STORE_POSTS':
      return Object.assign({}, state, {
        ...state,

        posts: action.payload.data,
      });
    case 'API_STORE_RESOLUTIONS':
      return Object.assign({}, state, {
        ...state,

        resolutions: action.payload.data,
      });
    case 'API_STORE_EXECUTIVE_ORDERS':
      return Object.assign({}, state, {
        ...state,

        executiveOrders: action.payload.data,
      });
    case 'API_STORE_ORDINANCES':
      return Object.assign({}, state, {
        ...state,

        ordinances: action.payload.data,
      });
    case 'API_STORE_ANNOUNCEMENTS':
      return Object.assign({}, state, {
        ...state,
        announcements: action.payload.data,
      });
    case 'API_STORE_NAV':
      return Object.assign({}, state, {
        ...state,
        selectedNav: action.payload.data,
      });

    default:
      return state;
  }
}

function apiData(state = apiDataInitialState, action) {
  switch (action.type) {
    case 'API_STORE_USER':
      if (action.payload.cb) {
        action.payload.cb();
      }
      return Object.assign({}, state, {
        ...state,
        userData: action.payload.data,
      });
    case 'API_LOGIN':
      // if (action.payload) {
      //   action.payload();
      // }
      return Object.assign({}, state, {
        ...state,
        isAuth: true,
      });
    case 'API_LOGOUT':
      if (action.payload) {
        action.payload();
      }
      return Object.assign({}, state, {
        ...state,
        isAuth: false,
      });
    default:
      return state;
  }
}
function SocketHandler(state = socket, action) {
  switch (action.type) {
    case 'SOCKET_HANDLER_INIT':
      return Object.assign({}, state, {
        ...state,
        socket: socketIOClient(process.env.REACT_APP_SOCKET_URL),
      });

    case 'SOCKET_HANDLER':
      return state;
    default:
      return state;
  }
}

const reducer = combineReducers({
  apiData,
  navData,
  globalDocViewer,
  globalModal,
  imageDetailDialogData,
  SocketHandler,
  sessionAuth,
});

export default reducer;
