import React from 'react';

import clsx from 'clsx';

import GlobalFilter from './GlobalFilter';
import { Toolbar, Typography, Tooltip, IconButton, Grid } from '@mui/material';
import { Box, display, lighten } from '@mui/system';
import AddPostDialog from './AddPostDialog';
import { DeleteRounded } from '@mui/icons-material';

// const useToolbarStyles = makeStyles(theme => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
//   title: {
//     flex: '1 1 100%',
//   },
// }));

const TableToolbar = props => {
  //const classes = useToolbarStyles()
  const {
    numSelected,
    addUserHandler,
    deleteUserHandler,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    setDialogAddPostOpen,
    dialogAddPostOpen,
    selectedRow,
    tableOperation,
    setTableOperation,
    selPostFilterPostLoc,
    setSelPostFilterPostLoc,
    chkAllFilter,
    setChkAllFilter,
    chkIsAnnouncement,
    setChkIsAnnouncement,
  } = props;
  return (
    <Toolbar
      variant="regular"
      // className={clsx(classes.root, {
      //   [classes.highlight]: numSelected > 0,
      // })}
      sx={{ '.MuiButton-root': { height: '50px' } }}
    >
      <Grid spacing={1} container>
        <Grid xs={12} sm={12} md={1.9} item sx={{ alignSelf: 'center' }}>
          <AddPostDialog
            addUserHandler={addUserHandler}
            setDialogAddPostOpen={setDialogAddPostOpen}
            dialogAddPostOpen={dialogAddPostOpen}
            selectedRow={selectedRow}
            tableOperation={tableOperation}
            setTableOperation={setTableOperation}
          />
        </Grid>
        {/* <Grid xs={12} sm={12} md={1.5} item sx={{ alignSelf: 'center' }}>
          {numSelected > 0 ? (
            <Typography
              //className={classes.title}
              color="inherit"
              variant="subtitle1"
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              //className={classes.title}
              sx={{ placeSelf: 'center' }}
              variant="h6"
              id="tableTitle"
            >
              Posts
            </Typography>
          )}
        </Grid> */}
        <Grid xs={12} sm={12} md={6} item>
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={deleteUserHandler}>
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          ) : (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              selPostFilterPostLoc={selPostFilterPostLoc}
              setSelPostFilterPostLoc={setSelPostFilterPostLoc}
              chkAllFilter={chkAllFilter}
              setChkAllFilter={setChkAllFilter}
              chkIsAnnouncement={chkIsAnnouncement}
              setChkIsAnnouncement={setChkIsAnnouncement}
            />
          )}{' '}
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', mx: 1 }}></Box>
    </Toolbar>
  );
};

// TableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   addUserHandler: PropTypes.func.isRequired,
//   deleteUserHandler: PropTypes.func.isRequired,
//   setGlobalFilter: PropTypes.func.isRequired,
//   preGlobalFilteredRows: PropTypes.array.isRequired,
//   globalFilter: PropTypes.string.isRequired,
// }

export default TableToolbar;
