import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import EnhancedTable from './components/EnhancedTable';
import { Post } from 'types/post';
import { Link as RouterLink } from 'react-router-dom';
import { Image } from 'mui-image';
import { width } from '@mui/system';
import moment from 'moment';
import ReactQuill from 'react-quill';
import useWindowDimensions from 'app/components/useWindowDimensions';
import contentAPI from 'api/contentAPI';
import { useDispatch } from 'react-redux';
import { UIToggleGlobalModal } from 'actions/actionCreator';
import { TTableOperation } from 'types/table';
import Misc from '../../../functions/misc';
import { GlobalColumn } from 'app/components/globalPostCell';
import userAPI from 'api/userAPI';
import { User } from 'types/user';
export const UserManagement = lazyLoad(
  () => import('./UserManagement'),
  module => module.UserManagementContent,
);

const SkeletonCont = () => {
  return (
    <Paper variant="outlined" sx={{ px: '12px', py: '7.5px', my: '7.5px' }}>
      <Skeleton variant="text" width="50%" animation="wave" />
      <Skeleton
        variant="text"
        width="50%"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton
        variant="rectangular"
        height="250px"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton variant="text" width="60%" animation="wave" />
    </Paper>
  );
};

export function UserManagementContent(props: any) {
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();

  const [users, setUsers] = React.useState<User[]>([]);

  const [data, setData] = React.useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [dialogAddPostOpen, setDialogAddPostOpen] = React.useState(false);

  const [isAnnouncementLoading, setIsAnnouncementLoading] =
    React.useState<Boolean>(false);
  const [isPostLoading, setIsPostLoading] = React.useState<Boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState({});

  const [tableOperation, setTableOperation] =
    React.useState<TTableOperation>('VIEW');

  const fetchUsers = React.useCallback(async () => {
    //setIsPostLoading(true);

    let _users = await userAPI.getAllActors(dispatch);
    //if (_posts.length > 3) _posts = _posts.slice(0, 3);
    setUsers(_users);

    //setIsPostLoading(false);
    //console.log(_users);
  }, []);

  React.useEffect(() => {
    //console.log('trigger');
    fetchUsers();
  }, []);
  React.useEffect(() => {
    //console.log('trigger', tableOperation);
    if (tableOperation === 'REFRESH') {
      fetchUsers();
      setTableOperation('VIEW');
    }
    //console.log(tableOperation);
  }, [dialogAddPostOpen, tableOperation]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    // @ts-ignore
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            // @ts-ignore
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },

      {
        Header: 'User Type',
        accessor: 'type',
        Cell: data => {
          const cell: any = data.cell.row.original;
          //console.log(cell);
          //return <></>;
          return <>{cell.type[0] === 0 ? 'Admin' : 'User'}</>;
        },
      },
      {
        Header: 'Last Login',
        accessor: 'lastLogin',
        Cell: data => {
          const cell: any = data.cell.row.original;
          //console.log(cell);
          //return <></>;
          return <>{Misc.parseAPIDateTimeFull(cell.lastLogin)}</>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>User Management</title>
        <meta name="description" content="User Management" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Typography variant="h3">User Management</Typography>
        <Divider />

        <Paper variant="outlined" sx={{ p: '15px', my: '15px' }}>
          {isPostLoading ? (
            <>
              <SkeletonCont />
              <SkeletonCont />
              <SkeletonCont />
            </>
          ) : (
            <EnhancedTable
              columns={columns}
              data={users}
              setData={setData}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              setDialogAddPostOpen={setDialogAddPostOpen}
              dialogAddPostOpen={dialogAddPostOpen}
              onRowSelectStateChange={setSelectedRowIds}
              selectedRow={selectedRow}
              tableOperation={tableOperation}
              setTableOperation={setTableOperation}
              getRowProps={row => ({
                onClick: () => {
                  // dispatch(
                  //   UIToggleGlobalModal({
                  //     title: 'test',
                  //     description: 'test',
                  //     isOpen: true,
                  //   }),
                  // );
                  setDialogAddPostOpen(true);
                  setTableOperation('VIEW');
                  setSelectedRow(row.original);
                  //console.log(row.original);
                },
                // style: {
                //   cursor: 'pointer',
                //   ':hover': { backgroundColor: 'red' },
                // },
              })}
            />
          )}
        </Paper>
      </Box>
    </>
  );
}
