import {
  AddCircle,
  CellTower,
  CloseRounded,
  DeleteForeverRounded,
  EditAttributesRounded,
  EditRounded,
  HelpCenterRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
} from '@mui/icons-material';
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Switch,
  AppBar,
  Toolbar,
  Typography,
  FormControlLabel,
  Box,
  Paper,
  Grid,
  FormControl,
  FormHelperText,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'mui-image';
import Select from 'react-select';
import { EPostStatus, Post, postStatusData } from 'types/post';
// import DatePicker from '@mui/lab/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ContentAPI from 'api/contentAPI';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import jsesc from 'jsesc';
import { fileURLToPath } from 'url';
import { GlobalReducer } from 'types/globalReducer';
import { use } from 'i18next';
import {
  apiStoreImageDetails,
  UIToggleGlobalModal,
} from 'actions/actionCreator';
import { TTableOperation } from 'types/table';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Misc from '../../../../functions/misc';
import { ISpecialPages, specialPages } from 'types/links';
import vidResize from '../quill-video/VideoResize';
import { User } from 'types/user';
import contentAPI from 'api/contentAPI';
const postTypeOptions = [
  { n: 'General', l: 'general' },
  { n: 'Mayors Office', l: 'mayors-office' },
  { n: 'Vice Mayors Office', l: 'vice-mayors-office' },
  { n: 'Accounting Office', l: 'accounting-office' },
  { n: 'Agriculture Office', l: 'agriculture-office' },
  { n: 'Assesors Office', l: 'assesors-office' },
  { n: 'Budget Office', l: 'budget-office' },
  { n: 'Engineering Office', l: 'engineering-office' },
  {
    n: 'Environmental & Natural Resources Office',
    l: 'environmental-and-natural-resources-office',
  },
  { n: 'Health Office', l: 'health-office' },
  { n: 'Local Civil Registrar Office', l: 'local-civil-registrar-office' },
  { n: 'Market and Slaughter House', l: 'market-and-slaughter-house' },
  {
    n: 'Municipal Disaster Risk Reduction Management Office',
    l: 'municipal-disaster-risk-reduction-management-office',
  },
  { n: 'Planning Office', l: 'planning-office' },
  {
    n: 'Social Welfare & Development Office',
    l: 'social-welfare-and-development-office',
  },
  { n: 'Treasurers Office', l: 'treasurers-office' },
  { n: 'Bureau of Fire Protection', l: 'bureau-of-fire-protection' },
  { n: 'Comelec', l: 'comelec' },
  { n: 'Department of Agrarian Reform', l: 'department-of-agrarian-reform' },
  {
    n: 'Department of Interior and Local Government',
    l: 'department-of-interior-and-local-government',
  },
  { n: 'Population Commmission', l: 'population-commmission' },
  { n: 'Philippine National Police', l: 'philippine-national-police' },
  { n: 'Post Office', l: 'post-office' },
  { n: 'Jobs', l: 'jobs' },

  { n: 'Bids and Awards', l: 'bids-and-awards' },
  { n: 'Executive Order', l: 'executive-order' },
  { n: 'Ordinance', l: 'ordinance' },
  { n: 'Resolution', l: 'resolution' },
  { n: 'Transparency Seal', l: 'transparency-seal' },
  { n: 'Downloads', l: 'downloads' },
];

const postUniqueLinkOptions = [
  { n: 'Post', l: 'posts' },
  { n: 'Announcement', l: 'announcements' },
];

interface PostType {
  n: string;
  l: string;
}

const initialUser = {
  firstName: '',
  lastName: '',
  age: 0,
  visits: 0,
  status: 'single',
  progress: 0,
  subRows: undefined,
};

function carouselPropsEqual(prev, next) {
  return (
    prev.imgFiles.length === next.imgFiles.length &&
    prev.tableOperation === next.tableOperation
  );
}

const ImgCarousel = props => {
  //console.log(props);
  //console.log(imgFiles);
  // const [imgDialogOpen, setImgDialogOpen] = React.useState<Boolean>(false);
  const { tableOperation }: { tableOperation: TTableOperation } = props;
  const { selectedRow }: { selectedRow: Post } = props;

  // useEffect(() => {
  //   console.log(props);
  // }, [props]);

  return (
    <Box>
      <Carousel
        //swipeable={true}
        //infiniteLoop
        showThumbs={false}
        centerMode
        showIndicators={false}
        centerSlidePercentage={84}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button
              disableRipple
              sx={{
                position: 'absolute',
                zIndex: 2,
                top: 'calc(50% - 15px)',
                width: 30,
                height: 30,
                left: 40,
                '& :hover': { backgroundColor: 'none' },
              }}
              onClick={onClickHandler}
            >
              <KeyboardArrowLeft
                sx={{
                  fontSize: '6rem',
                  color: 'white',
                  backgroundColor: 'primary.main',
                  opacity: '0.15',
                }}
              />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button
              disableRipple
              sx={{
                position: 'absolute',
                zIndex: 2,
                top: 'calc(50% - 15px)',
                width: 30,
                height: 30,
                right: 40,
                '& :hover': { backgroundColor: 'none' },
              }}
              onClick={onClickHandler}
            >
              <KeyboardArrowRight
                sx={{
                  fontSize: '6rem',
                  color: 'white',
                  backgroundColor: 'primary.main',
                  opacity: '0.15',
                }}
              />
            </Button>
          )
        }
      >
        {props.imgFiles.map((file: any, index: number) => (
          <React.Fragment key={index + 'imgCarousel'}>
            {props.tableOperation === 'VIEW' ? (
              <React.Fragment>
                <Image
                  //@ts-ignore
                  src={`${
                    process.env.REACT_APP_API_URL
                  }global/getFile?uniqueId=${
                    selectedRow.uniqueId
                  }&filename=${encodeURIComponent(file.filename)}`}
                  duration={0}
                  fit="contain"
                  height="300px"
                />
                <ImageDialog
                  imgDetailsArray={props.imgDetailsArray}
                  setImgDetailsArray={props.setImgDetailsArray}
                  tableOperation={tableOperation}
                  index={index}
                  file={file}
                />

                {/* <Button
                  sx={{ my: '7.5px', mx: '3.75px' }}
                  disableElevation
                  variant="contained"
                  disabled={props.tableOperation === 'VIEW'}
                  onClick={() => {
                    props.setPostBanner(file.name);
                    props.setPostBannerPreview(file);
                    //console.log(file);
                  }}
                >
                  Set as post header
                </Button> */}
              </React.Fragment>
            ) : null}
            {props.tableOperation === 'ADD' ? (
              <React.Fragment>
                <Image
                  //@ts-ignore
                  src={URL.createObjectURL(file)}
                  duration={0}
                  fit="contain"
                  height="300px"
                />
                <ImageDialog
                  imgDetailsArray={props.imgDetailsArray}
                  setImgDetailsArray={props.setImgDetailsArray}
                  tableOperation={tableOperation}
                  index={index}
                  file={file}
                />

                {/* <Button
                  sx={{ my: '7.5px', mx: '3.75px' }}
                  disableElevation
                  variant="contained"
                  disabled={props.tableOperation === 'VIEW'}
                  onClick={() => {
                    props.setPostBanner(file.name);
                    props.setPostBannerPreview(file);
                    //console.log(file);
                  }}
                >
                  Set as post header
                </Button> */}
              </React.Fragment>
            ) : null}
            {props.tableOperation === 'EDIT' ? (
              <React.Fragment>
                {typeof file.name == 'string' ? (
                  <Image
                    //@ts-ignore
                    src={URL.createObjectURL(file)}
                    duration={0}
                    fit="cover"
                    height="300px"
                  />
                ) : (
                  <Image
                    //@ts-ignore
                    src={`${
                      process.env.REACT_APP_API_URL
                    }global/getFile?uniqueId=${
                      selectedRow.uniqueId
                    }&filename=${encodeURIComponent(file.filename)}`}
                    duration={0}
                    fit="contain"
                    height="300px"
                  />
                )}

                <ImageDialog
                  imgDetailsArray={props.imgDetailsArray}
                  setImgDetailsArray={props.setImgDetailsArray}
                  tableOperation={tableOperation}
                  index={index}
                  file={file}
                />

                {/* <Button
                  sx={{ my: '7.5px', mx: '3.75px' }}
                  disableElevation
                  variant="contained"
                  disabled={props.tableOperation === 'VIEW'}
                  onClick={() => {
                    props.setPostBanner(file.filename);
                    props.setPostBannerPreview(file);
                    //console.log(file);
                  }}
                >
                  Set as post header
                </Button> */}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ))}
      </Carousel>
    </Box>
  );
};
function ImageDialog(props: any) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  interface IImageDetails {
    link: string;
    fileName: string;
    description: string;
  }
  const { tableOperation }: { tableOperation: TTableOperation } = props;

  const [open, setOpen] = React.useState(false);

  const [txtDescription, setTxtDescription] = React.useState('');
  const [txtLink, setTxtLink] = React.useState('');
  const imageDetails = useSelector(
    (state: GlobalReducer) => state.imageDetailDialogData.imageDetails,
  );
  React.useEffect(() => {
    //console.log('TABLE OPERATION DIALOG ===>', tableOperation);
    if (tableOperation === 'VIEW') {
      if (props.file) {
        if (imageDetails) {
          const imageInfo = imageDetails.find(
            x => x.fileName === props.file?.originalname,
          );
          if (imageInfo) {
            setTxtDescription(imageInfo?.description);
            setTxtLink(imageInfo?.link);
          }
        }

        // console.log(
        //   'IMAGE INFO ===>',
        //   imageDetails,
        //   imageInfo,
        //   props.file?.filename,
        // );
      }
    }
  }, [props]);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveImageInfo = () => {
    //console.log(imageDetails);

    let imgArray: IImageDetails[] = [];
    //console.log(imgArray, props.file, tableOperation);

    if (tableOperation === 'EDIT') {
      imgArray = imageDetails
        ? JSON.parse(jsesc(imageDetails, { json: true }))
        : [];

      if (
        imgArray.findIndex(x => x.fileName === props.file.originalname) !== -1
      ) {
        imgArray = imgArray.map(val =>
          val.fileName === props.file.originalname
            ? { ...val, description: txtDescription, link: txtLink }
            : val,
        );
      } else {
        const imageInfo = {
          description: txtDescription,
          link: txtLink,
          fileName: props.file.originalname,
        };
        imgArray.push(imageInfo);
      }
    } else {
      imgArray = imageDetails ? JSON.parse(imageDetails) : [];

      if (imgArray.findIndex(x => x.fileName === props.file.fileName) !== -1) {
        imgArray = imgArray.map(val =>
          val.fileName === props.file.name
            ? { ...val, description: txtDescription, link: txtLink }
            : val,
        );
      } else {
        const imageInfo = {
          description: txtDescription,
          link: txtLink,
          fileName: props.file.name,
        };
        imgArray.push(imageInfo);
      }
    }

    dispatch(apiStoreImageDetails(jsesc(imgArray, { json: true })));
    enqueueSnackbar('Image details attached');
    //console.log(imageDetails);

    //props.setImgDetailsArray(jsesc(imgArray, { json: true }));

    //console.log(props.imgDetailsArray);
    //console.log(JSON.parse(jsesc(props.imgDetailsArray, { json: true })));

    //console.log(jsesc(props.imgDetailsArray, { json: true }));
    // console.log(test.description.replace(/^"\/$/i, '"'));
  };

  return (
    <>
      <Button
        sx={{ my: '7.5px', mx: '3.75px' }}
        disableElevation
        variant="contained"
        onClick={handleClickOpen}
        disabled={props.tableOperation === 'VIEW'}
      >
        Add Info
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Image Information</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            label="Image Description"
            fullWidth
            variant="standard"
            onChange={e => setTxtDescription(e.target.value)}
            value={txtDescription}
          />
          {/* <TextField
            margin="dense"
            label="Image Link"
            fullWidth
            variant="standard"
            onChange={e => setTxtLink(e.target.value)}
            value={txtLink}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => {
              handleClose();
              saveImageInfo();
              //enqueueSnackbar('Image details saved');
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const MemoizedCarousel = React.memo(ImgCarousel, carouselPropsEqual);
const ImgDropZone = ({ setImgFiles, tableOperation, isImageOnly }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const allowedFiles = ['png', 'jpeg', 'jpg'];
  return (
    <>
      <Dropzone
        multiple={!isImageOnly}
        //maxSize={1000000}
        disabled={tableOperation === 'VIEW'}
        // accept={['.png', '.gif', '.jpeg', '.jpg']}
        onDrop={files => {
          let error: any = [];

          if (files) {
            //console.log(files);

            if (isImageOnly) {
              if (files.length > 1) {
                error.push('err imageOnly');
                enqueueSnackbar(
                  'You can only upload 1 image if post is image only',
                  {
                    variant: 'error',
                  },
                );
              }
            } else {
              files.forEach(e => {
                if (e.size > 3000000) {
                  error.push(e.name);
                  enqueueSnackbar('File size must not be greater than 3MB', {
                    variant: 'error',
                  });
                }

                const ext = e.name.substring(e.name.lastIndexOf('.') + 1);

                if (!allowedFiles.includes(ext)) {
                  error.push(e.name);
                  enqueueSnackbar(
                    'You can only upload (.jpg, .png, .jpeg) files',
                    {
                      variant: 'error',
                    },
                  );
                }
              });
            }
          }
          if (error.length === 0) {
            setImgFiles(files);
            enqueueSnackbar('Files attached', { variant: 'success' });
          }
        }}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          return (
            <>
              {tableOperation !== 'VIEW' && (
                <Box
                  {...getRootProps({ className: 'dropzone' })}
                  sx={{
                    border: '3px dashed black',
                    my: '15px',
                    p: '15px',
                    backgroundColor: '#eeeeee',
                    height: '100px',
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography sx={{ textAlign: 'center' }}>
                    Drag 'n' drop images here, or click to manually select
                    images
                  </Typography>
                </Box>
              )}
            </>
          );
        }}
      </Dropzone>
    </>

    // <Box
    //   {...getRootProps({ className: 'dropzone' })}
    //   sx={{ border: '3px dashed black', my: '15px', p: '15px' }}
    // >
    //   <div>
    //     <input {...getInputProps()} />
    //     <Typography sx={{ textAlign: 'center' }}>
    //       Drag 'n' drop some files here, or click to select files
    //     </Typography>
    //   </div>
    //   <aside>
    //     <Typography variant="h5" sx={{ textAlign: 'center' }}>
    //       Files
    //     </Typography>
    //     <ul>{files}</ul>
    //   </aside>
    // </Box>
  );
};

const DraftDialog = (props: any) => {
  return (
    <Dialog
      open={props.dialogDraftOpen}
      onClose={() => props.setDialogDraftOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title">
        {"Use Google's location service?"}
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want your post to be saved as draft?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setDialogDraftOpen(false)}>Close</Button>
        <Button onClick={() => props.setDialogDraftOpen(false)} autoFocus>
          Save Draft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IControlDisable {
  chkImgPost: boolean;
  chkHiddenPost: boolean;
  chkArchived: boolean;
  chkDeleteProtect: boolean;
  chkPinned: boolean;
  selPostType: boolean;
  selPostFor: boolean;
  selPostStatus: boolean;
  dpPostDate: boolean;
  dpPostEditedDate: boolean;
  txtPostTitle: boolean;
  txtPostShortDesc: boolean;
  txtPostContent: boolean;
  txtHeaderTitle: boolean;
  txtHeaderLink: boolean;
  txtHeaderDesc: boolean;
  filePicker: boolean;
  txtImageLink: boolean;
  txtLastModifiedBy: boolean;
}

const initValidatePost = {
  selPostUniqueLink: false,
  selPostLoc: false,
  txtPostTitle: false,
};

const AddPostDialog = props => {
  Quill.register('modules/VideoResize', vidResize);
  Quill.debug(false);
  // useEffect(() => {
  //   setOpen(true);
  //   //props.togglePostDialogOpen ? setOpen(false) : setOpen(true);
  // }, [props.togglePostDialogOpen]);

  // useMemo(()=>{})
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setTableOperation }: { setTableOperation(data: TTableOperation) } =
    props;
  const [user, setUser] = useState(initialUser);
  const { addUserHandler } = props;
  // const [open, setOpen] = React.useState(false);
  const [imgFiles, setImgFiles] = React.useState<File[]>([]);
  const [switchState, setSwitchState] = React.useState({
    addMultiple: false,
  });
  const quillElement = useRef(null);
  const [controlDisable, setControlDisable] =
    useState<IControlDisable | null>();

  const [isAnnouncement, setIsAnnouncement] = useState<Boolean>(false);
  const [isImageOnly, setIsImageOnly] = useState<Boolean>(false);
  const [isDraft, setIsDraft] = useState<Boolean>(false);
  const [isArchived, setIsArchived] = useState<Boolean>(false);
  const [isLocked, setIsLocked] = useState<Boolean>(false);
  const [isHidden, setIsHidden] = useState<Boolean>(false);
  const [isPinned, setIsPinned] = useState<Boolean>(false);
  const [isSpecialLink, setIsSpecialLink] = useState<Boolean>(false);

  const [filteredSpecialPages, setFilteredSpecialPages] = useState<
    ISpecialPages[]
  >([]);

  // const [isUniquePage, setIsUniquePage] = useState<Boolean>(false);
  // const [isEmergencyAnnouncement, setIsEmergencyAnnouncement] =
  //   useState<Boolean>(false);
  const [tableOperation, _setTableOperation] =
    React.useState<TTableOperation>('VIEW');
  const [dialogDraftOpen, setDialogDraftOpen] = useState<Boolean>(false);

  const [dpPostDate, setDpPostDate] = useState<Date | null>(null);
  const [dpEditDate, setDpEditDate] = useState<Date | null>(null);
  const [txtImageLink, setTxtImageLink] = useState<string | undefined>('');
  const [txtTitle, setTxtTitle] = useState<string | undefined>('');
  const [txtLastModifiedBy, setTxtLastModifiedBy] = useState<
    string | undefined
  >('');
  const [txtContent, setTxtContent] = useState('');
  const [txtPostDescription, setTxtPostDescription] = useState<
    string | undefined
  >('');

  const [txtHeaderTitle, setTxtHeaderTitle] = useState<string | undefined>('');
  const [txtHeaderDescription, setTxtHeaderDescription] = useState<
    string | undefined
  >('');
  const [txtHeaderLink, setTxtHeaderLink] = useState<string | undefined>('');

  const [selPostLoc, setSelPostLoc] = useState<PostType | null | undefined>(
    specialPages[0],
  );

  const [selPostStatus, setSelPostStatus] = useState<
    PostType | null | undefined
  >(postStatusData[0]);

  const [selPostUniqueLink, setSelPostUniqueLink] = useState<
    PostType | null | undefined
  >(postUniqueLinkOptions[0]);
  const [postBanner, setPostBanner] = useState('');
  const [postBannerPreview, setPostBannerPreview] = useState<any>('');

  const [triggerResetUI, setTriggerResetUI] = useState(false);

  const [selectedRow, _setSelectedRow] = useState<null | Post>(null);
  const [validatePost, setValidatePost] = useState(initValidatePost);
  const userData = useSelector(
    (state: GlobalReducer) => state.apiData.userData,
  );
  const [userType, setUserType] = useState(1);

  const filterIt = (arr, searchKey) => {
    return arr.filter(obj =>
      Object.keys(obj).some(key => obj[key].includes(searchKey)),
    );
  };

  useEffect(() => {
    const {
      selectedRow,
      tableOperation,
    }: { selectedRow: Post; tableOperation: TTableOperation } = props;

    if (props.tableOperation === 'VIEW') {
      toggleControlDisable(true);
      setTxtTitle(selectedRow.title);
      setTxtContent(selectedRow.postContent);
      //console.log(selectedRow.uniqueLink);
      setSelPostLoc(specialPages.find(x => x.l === selectedRow.postType));
      setSelPostUniqueLink(
        postUniqueLinkOptions.find(x => x.l === selectedRow.uniqueLink),
      );
      setTxtPostDescription(selectedRow.postDescription);
      setIsAnnouncement(Boolean(selectedRow.isAnnouncement));
      //setDpPostDate(Misc.parseAPIDate(selectedRow.datePosted));
      //@ts-ignore
      setDpPostDate(new Date(selectedRow.datePosted));
      //@ts-ignore
      setDpEditDate(new Date(selectedRow.dateEdited));
      setIsDraft(Boolean(selectedRow.isDraft));

      setIsImageOnly(Boolean(selectedRow.isImageOnly));

      setSelPostStatus(
        postStatusData.find(x => x.l === selectedRow.postStatus),
      );

      setTxtLastModifiedBy(selectedRow.editedBy?.name);

      // console.log(selectedRow);

      dispatch(apiStoreImageDetails(selectedRow.imageDescription));

      // async function getImages(filename: string) {
      //   return await ContentAPI.getFile(
      //     dispatch,
      //     selectedRow?.uniqueId ? selectedRow?.uniqueId : '',
      //     filename,
      //   );
      // }

      if (selectedRow.files) {
        const _files = JSON.parse(selectedRow.files);
        const filesArray: any[] = [];
        if (_files) {
          const _postBanner: any[] = filterIt(_files, selectedRow.postBanner);
          //console.log(_postBanner[0].filename);
          if (_postBanner.length > 0) {
            setPostBannerPreview({ filename: _postBanner[0].filename });
          }

          _files.forEach((val: File) => {
            filesArray.push(val);

            //filesArray.push(  URL.createObjectURL(val.));
          });
          //console.log(filesArray);
          setImgFiles(filesArray);
        }
      }
    }
    if (props.tableOperation === 'MODIFY') {
    }
    _setTableOperation(tableOperation);
    _setSelectedRow(selectedRow);
    //props.setDialogAddPostOpen(true);
  }, [props.selectedRow, props.tableOperation]);

  useEffect(() => {
    resetDialog();
    setSelPostLoc(null);
    //setSelPostUniqueLink(null);
    setIsLocked(true);
    setDpPostDate(moment().toDate());
  }, [triggerResetUI]);

  useEffect(() => {
    let tempFilteredSpecialPages: ISpecialPages[] = [];
    const UserPermissions: string[] = userData.pagePermissions
      ? userData.pagePermissions
      : [];
    if (UserPermissions.length > 0) {
      //Check if User has 'All' pages permission
      //console.log(UserPermissions)
      if (UserPermissions.includes('All')) {
        tempFilteredSpecialPages = [...specialPages];
        //console.log(specialPages)
      } else {
        UserPermissions.forEach(e => {
          const permission = specialPages.find(x => x.componentName === e);
          if (permission) tempFilteredSpecialPages.push(permission);
        });
      }
    }

    setFilteredSpecialPages(tempFilteredSpecialPages);

    const userType: any = userData.type ? userData?.type[0] : 1;
    setUserType(userType);
  }, []);

  //const [imgDetailsArray, setImgDetailsArray] = useState<any>('[]');
  const imageDetails = useSelector(
    (state: GlobalReducer) => state.imageDetailDialogData.imageDetails,
  );
  const dispatch = useDispatch();

  const validateUserInputs = (cb: VoidFunction) => {
    let tempUserInput = { ...validatePost };
    let error = false;
    // if (!isImageOnly) {

    // }
    selPostLoc
      ? (tempUserInput.selPostLoc = false)
      : (tempUserInput.selPostLoc = true);
    selPostUniqueLink
      ? (tempUserInput.selPostUniqueLink = false)
      : (tempUserInput.selPostUniqueLink = true);

    setValidatePost(tempUserInput);
    Object.keys(tempUserInput).forEach(key => {
      if (tempUserInput[key]) {
        error = true;
      }
    });
    if (error) enqueueSnackbar('Check Inputs', { variant: 'error' });
    else if (cb) cb();

    //console.log(tempUserInput);
  };

  const toggleControlDisable = (toggle: boolean) => {
    //setControlDisable(null);

    //Check User Permission if allowed
    let allowSelPostStatus = false;

    if (
      userData.pagePermissions?.includes('AllowApprovalOfPost') ||
      userData.type?.includes(0)
    ) {
      allowSelPostStatus = true;
    }

    setControlDisable({
      chkImgPost: toggle,
      chkHiddenPost: toggle,
      chkArchived: toggle,
      chkDeleteProtect: toggle,
      chkPinned: toggle,
      selPostType: toggle,
      selPostFor: toggle,
      selPostStatus: allowSelPostStatus ? toggle : true,
      dpPostDate: toggle,
      dpPostEditedDate: toggle,
      txtPostTitle: toggle,
      txtPostShortDesc: toggle,
      txtPostContent: toggle,
      txtHeaderTitle: toggle,
      txtHeaderLink: toggle,
      txtHeaderDesc: toggle,
      filePicker: toggle,
      txtImageLink: toggle,
      txtLastModifiedBy: toggle,
    });
    //console.log(controlDisable);
  };

  const resetDialog = () => {
    // toggleControlDisable(false);
    resetSwitch();
    setImgFiles([]);
    setTxtContent('');
    setTxtTitle('');
    setTxtPostDescription('');
    setIsAnnouncement(false);
    setIsImageOnly(false);
    setSelPostLoc(null);
    //setSelPostUniqueLink(null);
    setPostBanner('');
    setIsDraft(false);
    setIsSpecialLink(false);
    setImgFiles([]);
    setTxtImageLink('');
    setPostBanner('');
    setPostBannerPreview('');
    setValidatePost(initValidatePost);
    setSelPostUniqueLink(postUniqueLinkOptions[0]);
  };

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    props.setDialogAddPostOpen(true);
  };

  const handleClose = (event?, reason?) => {
    if (reason && reason == 'backdropClick') return;

    props.setDialogAddPostOpen(false);
    if (!isDraft) setDialogDraftOpen(false);

    //resetDialog();
  };

  const handleAdd = () => {
    setTableOperation('ADD');
    setTriggerResetUI(triggerResetUI ? false : true);
    toggleControlDisable(false);

    setSelPostStatus(postStatusData[0]);
    props.setDialogAddPostOpen(true);
  };

  const handleModify = () => {
    setTableOperation('EDIT');
    toggleControlDisable(false);

    //setControlDisable(null);
    // console.log('trigger');
  };
  const handleModifySave = () => {
    //console.log(imageDetails);
    validateUserInputs(() => {
      const _imageDetails = imageDetails
        ? imageDetails.length > 0
          ? imageDetails
          : '[]'
        : '[]';
      const postData: Post = {
        isAnnouncement: selPostUniqueLink
          ? selPostUniqueLink.l === 'announcement' ||
            selPostUniqueLink.l === 'announcements'
            ? 1
            : 0
          : 0,
        isImageOnly: Number(isImageOnly),
        imageLink: txtImageLink,
        title: txtTitle,
        name: txtTitle,
        postDescription: txtPostDescription,
        postContent: txtContent,
        postBanner: postBanner,
        postType: selPostLoc?.l,
        uniqueLink: selPostUniqueLink?.l,
        imageDescription: _imageDetails,
        isHidden: Number(isHidden),
        isArchived: Number(isArchived),
        isPinned: Number(isPinned),
        isLocked: Number(isLocked),
        isDraft: Number(isDraft),
        headerTitle: txtHeaderTitle,
        headerLink: txtHeaderLink,
        headerDescription: txtHeaderDescription,
        datePosted: Misc.parseAPIDateTime(dpPostDate),
        postStatus:
          EPostStatus[
            selPostStatus?.l
              ? (selPostStatus?.l as keyof typeof EPostStatus)
              : ('NEW' as keyof typeof EPostStatus)
          ],
        id: selectedRow?.id,
        //files: imgFiles,
      };
      console.log('PRE-POST UPDATE DATA ====>', postData);
      setTableOperation('REFRESH');
      ContentAPI.updatePost(dispatch, postData, imgFiles, 100, () => {
        enqueueSnackbar('Post Modified', { variant: 'success' });
        handleClose();
      });
    });
  };
  const handleDelete = () => {
    setTableOperation('DELETE');
  };
  const handleDeleteSave = () => {
    if (selectedRow?.isLocked === 1 && userType === 1) {
      dispatch(
        UIToggleGlobalModal({
          title: 'Action not allowed',
          description: (
            <>
              <Typography>
                This post is delete protected. Please contact administrator.
              </Typography>
            </>
          ),
          isOpen: true,
          isYesNo: true,
        }),
      );
    } else {
      setTableOperation('REFRESH');
      ContentAPI.deletePost(dispatch, Number(selectedRow?.id), () => {
        enqueueSnackbar('Post Deleted', { variant: 'success' });
        handleClose();
      });
    }
  };
  const handleCancel = () => {
    setTableOperation('VIEW');
  };
  const handleAddSave = event => {
    //addUserHandler(user);
    //setUser(initialUser);
    validateUserInputs(() => {
      // switchState.addMultiple
      //   ? props.setDialogAddPostOpen(true)
      //   : props.setDialogAddPostOpen(false);
      const _imageDetails = imageDetails
        ? imageDetails.length > 0
          ? imageDetails
          : '[]'
        : '[]';
      const postData: Post = {
        isAnnouncement: selPostUniqueLink
          ? selPostUniqueLink.l === 'announcement' ||
            selPostUniqueLink.l === 'announcements'
            ? 1
            : 0
          : 0,
        isImageOnly: Number(isImageOnly),
        imageLink: txtImageLink,
        title: txtTitle,
        name: txtTitle,
        postDescription: txtPostDescription,
        postContent: txtContent,
        postBanner: postBanner,
        postType: selPostLoc?.l,
        uniqueLink: selPostUniqueLink?.l,
        imageDescription: _imageDetails,
        isHidden: Number(isHidden),
        isArchived: Number(isArchived),
        isPinned: Number(isPinned),
        isLocked: Number(isLocked),
        isDraft: Number(isDraft),
        headerTitle: txtHeaderTitle,
        headerLink: txtHeaderLink,
        headerDescription: txtHeaderDescription,
        datePosted: Misc.parseAPIDateTime(dpPostDate),
        postStatus:
          EPostStatus[
            selPostStatus?.l
              ? (selPostStatus?.l as keyof typeof EPostStatus)
              : ('NEW' as keyof typeof EPostStatus)
          ],
        //files: imgFiles,
      };
      //console.log('PRE-POST DATA ====>', postData);
      setTableOperation('REFRESH');
      ContentAPI.createPost(dispatch, postData, imgFiles, 100, () => {
        enqueueSnackbar('Post Added', { variant: 'success' });
        handleClose();
      });
    });
  };

  const handleChange =
    name =>
    ({ target: { value } }) => {
      setUser({ ...user, [name]: value });
    };

  const customSelStyles = {
    menu: (provided, state) => ({
      ...provided,
      // width: state.selectProps.width,
      // borderBottom: '1px dotted pink',
      // color: state.selectProps.menuColor,
      // padding: 20,
      zIndex: 500,
    }),

    control: (provided, { selectProps: { error } }) => ({
      ...provided,
      borderColor: error ? 'red' : 'grey',
      boxShadow: 'none',
      '&:hover': {
        borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.87)',
      },
    }),

    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    //   return { ...provided, opacity, transition };
    // },
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', { align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ color: [] }, { background: [] }],
          ['link', 'video'],
        ],
        handlers: {
          video: value => {
            var href = prompt(
              'Enter video Iframe\nFor Facebook and Youtube video:\n      Please include the full iframe ex:\n      <iframe>...</iframe>',
            );
            if (value) {
              //console.log(value);

              if (href) {
                const regex = /facebook|youtube/gi;

                const found = href.match(regex);

                const regexFacebook = /height|width|appId|t=/gi;
                if (found && quillElement.current) {
                  const parser = new DOMParser();
                  const _document = parser.parseFromString(href, 'text/html');
                  const _iframe = _document.getElementsByTagName('iframe')[0];
                  // console.log('IFRAME ===>', _iframe);

                  // const searchTypePost = _iframe.src.search('post.php')
                  // if(searchTypePost){

                  // }

                  //console.log('FIND POST ===>', _iframe.src.search('post.php'));
                  let splitLink = _iframe.src.split(/[?&]/);
                  splitLink = splitLink.filter(e => !e.match(regexFacebook));
                  splitLink[0] = splitLink[0] + '?';
                  splitLink.push('&height=450');
                  splitLink.push('&height=450');
                  const finalSrc = splitLink.join('');
                  //console.log(finalSrc);
                  //@ts-ignore
                  quillElement.current.getEditor().format('video', finalSrc);
                } else {
                  //@ts-ignore
                  quillElement.current.getEditor().format('video', href);
                }
              }
            } else {
              //@ts-ignore
            }
          },
        },
      },
      VideoResize: {
        modules: ['Resize', 'DisplaySize'],
      },
    }),
    [],
  );

  // const toolbarOptions = [
  //   [
  //     'bold',
  //     'italic',
  //     'underline',
  //     { align: [] },
  //     { list: 'ordered' },
  //     { list: 'bullet' },
  //   ], // toggled buttons
  //   ['blockquote', 'link', 'video'],

  //   //[{ header: 1 }, { header: 2 }], // custom button values

  //   [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  //   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  //   // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //   [{ font: [] }],
  //   [{ color: [] }, { background: [] }], // dropdown with defaults from theme

  //   ['clean'], // remove formatting button
  //   [
  //     {
  //       handlers: {
  //         link: e => {
  //           console.log(e);
  //         },
  //       },
  //     },
  //   ],
  // ];

  return (
    <div>
      <Tooltip title="Add">
        {/* <IconButton
          aria-label="add"
          onClick={() => {
            handleAdd();
          }}
        >
          <AddCircle />
          Add
        </IconButton> */}
        <Button
          aria-label="add"
          variant="contained"
          disableElevation
          onClick={() => {
            handleAdd();
          }}
        >
          <AddCircle /> Add Post
        </Button>
      </Tooltip>
      <DraftDialog
        dialogDraftOpen={dialogDraftOpen}
        setDialogDraftOpen={setDialogDraftOpen}
      />
      <Dialog
        open={props.dialogAddPostOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        disableEscapeKeyDown

        //fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Tooltip title="Close Dialog">
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{ backgroundColor: 'warning.dark', color: 'white' }}
                startIcon={<CloseRounded />}
              >
                Close
              </Button>
            </Tooltip>

            {/* <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >

            </IconButton> */}
            <Typography
              sx={{ ml: 2, flex: 1, color: 'white' }}
              variant="h6"
              component="div"
            >
              {tableOperation === 'REFRESH'
                ? 'Data is loading...'
                : `${tableOperation} Post`}
            </Typography>
            {tableOperation === 'ADD' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'success.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleAddSave}
                  startIcon={<Save />}
                >
                  Save
                </Button>
              </>
            ) : null}
            {tableOperation === 'VIEW' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'info.main',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={() => {
                    handleModify();
                  }}
                  startIcon={<EditRounded />}
                >
                  Modify
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'error.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleDelete}
                  startIcon={<DeleteForeverRounded />}
                >
                  Delete
                </Button>
              </>
            ) : null}
            {tableOperation === 'EDIT' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'success.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleModifySave}
                  startIcon={<Save />}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'warning.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : null}
            {tableOperation === 'DELETE' ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'error.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleDeleteSave}
                  startIcon={<DeleteForeverRounded />}
                >
                  Remove
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'warning.light',
                    color: 'background.default',
                    mx: 1,
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : null}
          </Toolbar>
        </AppBar>
        {/* <DialogTitle id="form-dialog-title">Add Post</DialogTitle> */}
        {tableOperation === 'REFRESH' ? (
          <Box sx={{ p: 3, width: '100%', textAlign: 'center' }}>
            <Typography sx={{ mb: 2 }}>Processing Post...</Typography>
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent sx={{ m: 0, p: 0 }}>
            {/* <Paper
            variant="elevation"
            square
            elevation={3}
            sx={{ backgroundColor: 'blue', m: 0, p: 1 }}
          >
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              This post is saved as draft
            </Typography>
          </Paper> */}
            <Grid container sx={{ backgroundColor: 'ThreeDFace' }}>
              {selectedRow?.postStatus === EPostStatus.NEW &&
                tableOperation === 'EDIT' && (
                  <Grid item>
                    <Button
                      sx={{ m: 2 }}
                      disableElevation
                      variant="contained"
                      onClick={() => {
                        contentAPI.submitPostForVerification(
                          dispatch,
                          selectedRow?.id,
                          0,
                          () => {
                            setTableOperation('REFRESH');
                            enqueueSnackbar('Post Submitted', {
                              variant: 'success',
                            });
                            handleClose();
                          },
                        );
                      }}
                      disabled={props.tableOperation === 'VIEW'}
                    >
                      Submit For Verification
                    </Button>
                  </Grid>
                )}
            </Grid>
            <Box sx={{ p: 3.5 }}>
              {/* <FormControlLabel
              sx={{ width: '100%' }}
              control={
                <Switch
                  checked={Boolean(isAnnouncement)}
                  onChange={() => {
                    isAnnouncement
                      ? setIsAnnouncement(false)
                      : setIsAnnouncement(true);
                  }}
                />
              }
              label="Announcement"
            /> */}

              <Grid container>
                {/* <Grid item xs={6}>
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        disabled={controlDisable?.chkImgPost}
                        checked={Boolean(isImageOnly)}
                        onChange={() => {
                          isImageOnly
                            ? setIsImageOnly(false)
                            : setIsImageOnly(true);
                        }}
                      />
                    }
                    label="Image Post Only"
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        disabled={controlDisable?.chkDeleteProtect}
                        checked={Boolean(isLocked)}
                        onChange={() => {
                          isLocked ? setIsLocked(false) : setIsLocked(true);
                        }}
                      />
                    }
                    label="Delete Protect"
                  />
                </Grid>
                {/* <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkHiddenPost}
                      checked={Boolean(isHidden)}
                      onChange={() => {
                        isHidden ? setIsHidden(false) : setIsHidden(true);
                      }}
                    />
                  }
                  label="Hidden Post"
                />
              </Grid> */}
                {/* <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkPinned}
                      checked={Boolean(isPinned)}
                      onChange={() => {
                        isPinned ? setIsPinned(false) : setIsPinned(true);
                      }}
                    />
                  }
                  label="Pinned"
                />
              </Grid> */}
                {/* <Grid item xs={6}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Switch
                      disabled={controlDisable?.chkArchived}
                      checked={Boolean(isArchived)}
                      onChange={() => {
                        isArchived ? setIsArchived(false) : setIsArchived(true);
                      }}
                    />
                  }
                  label="Archived"
                />
              </Grid> */}
              </Grid>
              <>
                <Typography sx={{ my: '7.5px' }}>Post Type</Typography>
                <Select
                  //@ts-ignore
                  styles={customSelStyles}
                  options={postUniqueLinkOptions}
                  // isDisabled={controlDisable?.selPostType}
                  isDisabled={true}
                  getOptionValue={item => item.l}
                  getOptionLabel={item => item.n}
                  value={selPostUniqueLink}
                  onChange={e => setSelPostUniqueLink(e)}
                  error={validatePost.selPostLoc}
                />
                <Typography sx={{ my: '7.5px' }}>Post for</Typography>
                <Select
                  //@ts-ignore
                  styles={customSelStyles}
                  options={filteredSpecialPages}
                  isDisabled={controlDisable?.selPostFor}
                  getOptionValue={item => item.l}
                  getOptionLabel={item => item.n}
                  value={selPostLoc}
                  onChange={e => setSelPostLoc(e)}
                  error={validatePost.selPostUniqueLink}
                />
                <Typography sx={{ my: '7.5px' }}>Post Status</Typography>
                <Select
                  //@ts-ignore
                  styles={customSelStyles}
                  options={postStatusData}
                  isDisabled={controlDisable?.selPostStatus}
                  getOptionValue={item => item.l}
                  getOptionLabel={item => item.n}
                  value={selPostStatus}
                  onChange={e => setSelPostStatus(e)}
                  // error={validatePost.selPostUniqueLink}
                />

                <Grid sx={{ my: 2 }} spacing={2} container>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Post Date"
                      value={dpPostDate}
                      disabled={controlDisable?.dpPostDate}
                      onChange={newValue => {
                        // console.log(
                        //   moment(newValue).format('YYYY-MM-DD HH:mm:ss'),
                        // );
                        setDpPostDate(newValue);
                      }}
                      renderInput={params => (
                        <TextField sx={{ width: '100%' }} {...params} />
                      )}
                    />
                  </Grid>
                  {tableOperation === 'VIEW' && (
                    <React.Fragment>
                      <Grid item xs={6}>
                        <DatePicker
                          label="Last Edited Date"
                          value={dpEditDate}
                          disabled={controlDisable?.dpPostEditedDate}
                          onChange={newValue => {
                            setDpPostDate(newValue);
                          }}
                          renderInput={params => (
                            <TextField sx={{ width: '100%' }} {...params} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="addPostDialogModifiedByActorField"
                          label="Last Modified By"
                          value={txtLastModifiedBy}
                          onChange={e => setTxtLastModifiedBy(e.target.value)}
                          type="text"
                          fullWidth
                          disabled={true}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>

                <TextField
                  id="standard-multiline-flexible"
                  label="Post Title"
                  multiline
                  rows={3}
                  value={txtTitle}
                  onChange={e => setTxtTitle(e.target.value)}
                  margin="dense"
                  type="text"
                  fullWidth
                  disabled={controlDisable?.txtPostTitle}
                />
                {/* <FormControl sx={{ my: '7.5px', width: '100%' }}>
                    <TextField
                      id="standard-multiline-flexible"
                      label="Link"
                      value={txtHeaderLink}
                      onChange={e => setTxtHeaderLink(e.target.value)}
                      type="text"
                      fullWidth
                      disabled={controlDisable?.txtHeaderDesc}
                    />
                    <FormHelperText id="component-helper-text">
                      If this field is filled, The post title will be
                      transformed to a hyperlink / link to a external website.
                    </FormHelperText>
                  </FormControl> */}

                {/* <TextField
                    id="standard-multiline-flexible"
                    label="Post Short Description"
                    multiline
                    rows={3}
                    value={txtPostDescription}
                    onChange={e => setTxtPostDescription(e.target.value)}
                    margin="dense"
                    type="text"
                    fullWidth
                    disabled={controlDisable?.txtPostShortDesc}
                  /> */}

                {/* <TextField
            autoFocus
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            value={user.firstName}
            onChange={handleChange('firstName')}
          /> */}
                <Typography sx={{ my: '7.5px' }}>Post Content</Typography>
                {/* <Tooltip title="Post Content Help">
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      sx={{
                        backgroundColor: 'info.dark',
                        color: 'white',
                        mb: 2,
                      }}
                      startIcon={<HelpCenterRounded />}
                    >
                      Help
                    </Button>
                  </Tooltip> */}
                <Box
                  sx={{
                    '& .ql-editor iframe': {
                      pointerEvents: 'none',
                      height: '500px',
                    },
                  }}
                >
                  <ReactQuill
                    // modules={{
                    //   toolbar: toolbarOptions,
                    //   VideoResize: {
                    //     modules: ['Resize', 'DisplaySize'],
                    //   },
                    // }}
                    modules={modules}
                    theme="snow"
                    ref={quillElement}
                    value={txtContent}
                    onChange={setTxtContent}
                    readOnly={controlDisable?.txtPostContent}
                  />
                </Box>
              </>
              {/* {isSpecialLink ? (
                <>
                  <Select
                    //@ts-ignore
                    styles={customSelStyles}
                    options={specialPages}
                    isDisabled={controlDisable?.selPostFor}
                    getOptionValue={item => item.l}
                    getOptionLabel={item => item.n}
                    value={selPostLoc}
                    onChange={e => setSelPostLoc(e)}
                  />

                  <TextField
                    id="standard-multiline-flexible"
                    label="Title"
                    sx={{ my: '7.5px' }}
                    value={txtHeaderTitle}
                    onChange={e => setTxtHeaderTitle(e.target.value)}
                    type="text"
                    fullWidth
                    disabled={controlDisable?.txtHeaderTitle}
                  />
                  <TextField
                    id="standard-multiline-flexible"
                    label="Link"
                    sx={{ my: '7.5px' }}
                    value={txtHeaderLink}
                    onChange={e => setTxtHeaderLink(e.target.value)}
                    type="text"
                    fullWidth
                    disabled={controlDisable?.txtHeaderDesc}
                  />
                  <TextField
                    id="standard-multiline-flexible"
                    label="Short Description"
                    sx={{ my: '7.5px' }}
                    multiline
                    rows={3}
                    value={txtHeaderDescription}
                    onChange={e => setTxtHeaderDescription(e.target.value)}
                    margin="dense"
                    type="text"
                    fullWidth
                    disabled={controlDisable?.txtHeaderDesc}
                  />
                </>
              ) : null}
              {!isImageOnly && !isSpecialLink ? (
              <></>
              ) : (
                <>
                  <Typography sx={{ my: '7.5px' }}>Post Type</Typography>
                  <Select
                    //@ts-ignore
                    styles={customSelStyles}
                    options={postUniqueLinkOptions}
                    isDisabled={controlDisable?.selPostType}
                    getOptionValue={item => item.l}
                    getOptionLabel={item => item.n}
                    value={selPostUniqueLink}
                    onChange={e => setSelPostUniqueLink(e)}
                    error={validatePost.selPostLoc}
                  />
                  <Typography sx={{ my: '7.5px' }}>Post for</Typography>
                  <Select
                    //@ts-ignore
                    styles={customSelStyles}
                    options={filteredSpecialPages}
                    isDisabled={controlDisable?.selPostFor}
                    getOptionValue={item => item.l}
                    getOptionLabel={item => item.n}
                    value={selPostLoc}
                    onChange={e => setSelPostLoc(e)}
                    error={validatePost.selPostUniqueLink}
                  />

                  <Grid sx={{ my: 2 }} spacing={2} container>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Post Date"
                        value={dpPostDate}
                        disabled={controlDisable?.dpPostDate}
                        onChange={newValue => {
                          // console.log(
                          //   moment(newValue).format('YYYY-MM-DD HH:mm:ss'),
                          // );
                          setDpPostDate(newValue);
                        }}
                        renderInput={params => (
                          <TextField sx={{ width: '100%' }} {...params} />
                        )}
                      />
                    </Grid>
                    {tableOperation === 'VIEW' ? (
                      <Grid item xs={6}>
                        <DatePicker
                          label="Last Edited Date"
                          value={dpEditDate}
                          disabled={controlDisable?.dpPostEditedDate}
                          onChange={newValue => {
                            setDpPostDate(newValue);
                          }}
                          renderInput={params => (
                            <TextField sx={{ width: '100%' }} {...params} />
                          )}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <FormControl sx={{ my: '7.5px', width: '100%' }}>
                    <TextField
                      id="standard-multiline-flexible"
                      label="Image Link"
                      value={txtImageLink}
                      onChange={e => setTxtImageLink(e.target.value)}
                      type="text"
                      fullWidth
                      disabled={controlDisable?.txtImageLink}
                    />
                    <FormHelperText id="component-helper-text">
                      Image link to external website
                    </FormHelperText>
                  </FormControl>
                </>
              )} */}
              <Box sx={{ mt: 2 }}>
                <ImgDropZone
                  setImgFiles={setImgFiles}
                  tableOperation={tableOperation}
                  isImageOnly={isImageOnly}
                />
              </Box>

              {/* {postBannerPreview ? (
                <Paper variant="outlined" sx={{ p: 4 }}>
                  <Typography variant="h5">Selected Post Banner</Typography>
                  {tableOperation === 'EDIT' || tableOperation === 'VIEW' ? (
                    <Image
                      src={`${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${selectedRow?.uniqueId}&filename=${postBannerPreview.filename}`}
                      duration={0}
                      fit="contain"
                      height="300px"
                      // height="750px"
                    />
                  ) : null}
                  {tableOperation === 'ADD' ? (
                    <Image
                      //@ts-ignore
                      src={URL.createObjectURL(postBannerPreview)}
                      duration={0}
                      fit="contain"
                      height="300px"
                    />
                  ) : null}
                </Paper>
              ) : null} */}
              <MemoizedCarousel
                imgFiles={imgFiles}
                setPostBanner={setPostBanner}
                setPostBannerPreview={setPostBannerPreview}
                tableOperation={tableOperation}
                selectedRow={selectedRow}
              />
            </Box>
            {/* <DialogContentText>Demo add item to react table.</DialogContentText> */}
          </DialogContent>
        )}

        {/* <DialogActions>
          <Tooltip title="Add multiple">
            <Switch
              checked={switchState.addMultiple}
              onChange={handleSwitchChange('addMultiple')}
              value="addMultiple"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Tooltip>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default AddPostDialog;
