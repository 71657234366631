export enum EPostStatus {
  LIVE = 'LIVE',
  PENDING = 'PENDING',
  NEW = 'NEW',
}

export const postStatusData = [
  { n: 'New', l: 'NEW' },
  { n: 'Live', l: 'LIVE' },
  { n: 'Pending', l: 'PENDING' },

];

export interface Post {
  id?: number;
  title?: string;
  name?: string;
  headerTitle?: string;
  headerDescription?: string;
  headerLink?: string;
  postOwnerId?: number;
  datePosted?: string;
  dateEdited?: string;
  editedById?: number;
  postGroupId?: number;
  isArchived?: number;
  isHidden?: number;
  isLocked?: number;
  isPinned?: number;
  isAnnouncement?: number;
  isEmergencyAnnouncement?: number;
  postContent?: any;
  postDescription?: string;
  readCount?: number;
  viewsCount?: number;
  sharesCount?: number;
  likesCount?: number;
  uniqueLink?: string;
  isUniquePage?: number;
  isDraft?: number;
  postBanner?: string;
  postType?: string;
  isImageOnly?: number;
  imageLink?: string | null;
  imageDescription?: any;
  postStatus?: EPostStatus;
  readonly uniqueId?: string;
  readonly editedBy?: any;
  readonly postOwner?: any;
  readonly postGroup?: any;
  readonly files?: any;
}
