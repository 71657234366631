import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from 'reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { createStore } from 'redux';
import reducer from './reducer/reducer';
import { applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas/sagas';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import './styles/global.css';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const theme = createTheme({
  // components: {
  //   MuiFormLabel: {
  //     styleOverrides: {
  //       focused: {
  //         color: 'black',
  //       },
  //       root: {
  //         color: 'black',
  //       },
  //     },
  //   },
  //   MuiInputLabel: {
  //     styleOverrides: {
  //       focused: {
  //         color: 'black',
  //       },
  //       root: {
  //         color: 'black',
  //       },
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: '#1ab962',
    },
    secondary: {
      main: '#0a80b5',
    },
    background: {
      paper: '#fcfcfc',
    },
  },
});

//
ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
  () => {
    sagaMiddleware.run(rootSaga);
  },
);

// Hot reloadable translation json files
// if (module.hot) {
//   module.hot.accept(['./locales/i18n'], () => {
//     // No need to render the App again because i18next works with the hooks
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
