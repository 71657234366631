/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
//import { GlobalStyle } from 'styles/global-styles';

import { NotFoundPage } from './components/NotFoundPage/Loadable';
//import Divider from '@mui/material/Divider';

import { Navigate, useLocation } from 'react-router';

import useWindowDimensions from './components/useWindowDimensions';

import MainDrawer from './pages/indexComponents/MainDrawer';
import GlobalDialog from './pages/indexComponents/GlobalDialog';
import { Management } from './pages/ManagementPage/Management';
import { Login } from './pages/LoginPage/Login';
import { GlobalReducer, ISocketHandler } from 'types/globalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { PostPage } from './pages/PostPage/PostPage';
import { UserManagement } from './pages/UserPage/UserManagement';
import { User } from 'types/user';
import {
  apiRequest,
  apiStoreLoggedUser,
  login,
  logout,
  SocketHandlerInit,
  UIToggleGlobalModal,
} from 'actions/actionCreator';

import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Socket } from 'socket.io-client';
let CryptoJS = require('crypto-js');
export function App() {
  const { width } = useWindowDimensions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAuth = useSelector((state: GlobalReducer) => state.apiData.isAuth);
  const dispatch = useDispatch();
  const [_isAuth, setIsAuth] = useState<any>(null);
  const userData: User = useSelector(
    (state: GlobalReducer) => state.apiData.userData,
  );

  const pagePermissions: string[] = userData.pagePermissions
    ? userData.pagePermissions
    : [];
  const userType: number[] = userData.type ? userData.type : [];
  React.useEffect(() => {
    dispatch(SocketHandlerInit());
  }, []);

  const RequireAuth = ({
    children,
    permission,
  }: {
    children: JSX.Element;
    permission?: string;
  }) => {
    let location = useLocation();

    const backToLogin = <Navigate to="/" state={{ from: location }} replace />;
    const unAuthAlert = () =>
      enqueueSnackbar('You have been logged out', {
        variant: 'info',
      });
    const noPermAlert = () =>
      enqueueSnackbar('You dont have permission to view this page', {
        variant: 'error',
      });
    const LUT: string | null =
      localStorage.getItem('LUT') !== null ? localStorage.getItem('LUT') : '';

    React.useEffect(() => {
      setIsAuth(isAuth);
    }, [isAuth]);

    if (!LUT) {
      unAuthAlert();
      return backToLogin;
    } else {
      if (!isAuth) {
        if (localStorage.getItem('LUT') != null) {
          let decUserData = JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem('LUD'),
              process.env.REACT_APP_SEK,
            ).toString(CryptoJS.enc.Utf8),
          );
          dispatch(apiStoreLoggedUser(decUserData, () => {}));
          dispatch(login());
        }

        // return backToLogin;
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
      } else {
        switch (permission) {
          case 'userPage':
            if (userType.includes(0)) {
              break;
              //return children;
            } else {
              noPermAlert();
              return backToLogin;
            }

          default:
            break;
          // return children;
        }
      }

      return _isAuth && children;
    }
  };

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s | Tigbauan CMS"
        defaultTitle="Municipality of Tigbauan Content Management System"
        //htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="Municipality of Tigbauan Content Management System"
        />
      </Helmet>
      <GlobalDialog />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {isAuth ? <MainDrawer /> : null}

        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/post-management"
            element={
              <RequireAuth>
                <Management />
              </RequireAuth>
            }
          />
          <Route
            path="/user-management"
            element={
              <RequireAuth permission="userPage">
                <UserManagement />
              </RequireAuth>
            }
          />

          <Route
            path="post/:postId"
            element={
              <RequireAuth>
                <PostPage />
              </RequireAuth>
            }
          />
          <Route
            path="*"
            element={
              <>
                <NotFoundPage />
              </>
            }
          />
        </Routes>
      </Box>

      {/* <GlobalStyle /> */}
    </BrowserRouter>
  );
}
