import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  delay,
  select,
} from 'redux-saga/effects';

import axios from 'axios';
import Filters from '../functions/filters';
import AuthenticationAPI from '../api/authentication';
import { GlobalReducer } from 'types/globalReducer';
export function* API_REQUEST(action) {
  let response = [];
  const socketio = yield select(state => state.SocketHandler.socket);
  let serv = process.env.REACT_APP_API_URL;
  const loadId = Filters.uuidv4();
  //console.log(serv + action.payload);

  // yield put({
  //   type: 'API_TOGGLE_LOADING',
  //   payload: {
  //     payload: { isLoading: true, name: action.loadingMsg, id: loadId },
  //   },
  // });

  const API_CALL = () => {
    switch (action.reqType) {
      case 'getFile':
        return axios
          .get(serv + action.payload, {
            responseType: 'arraybuffer',
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),
            },
          })
          .then(resp => {
            return resp;
          })
          .catch(error => {
            throw error;
          });
      case 'get':
        return axios
          .get(serv + action.payload, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),
            },
          })
          .then(resp => {
            return resp;
          })
          .catch(error => {
            throw error;
          });
      case 'post':
        //console.log(action.payload);
        return axios
          .post(serv + action.payload, action.data, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),

              ApiKey: '6f814e31-05d7-4924-bf86-3b644cd058a0',
            },
          })
          .then(resp => {
            return resp;
          })
          .catch(error => {
            throw error;
          });
      case 'postFiles':
        //console.log(action.payload);

        return axios
          .post(serv + action.payload, action.data, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),
            },
          })
          .then(resp => {
            return resp;
          })
          .catch(error => {
            throw error;
          });
      case 'put':
        //console.log(action.data);
        return axios
          .put(serv + action.payload, action.data, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),
              ApiKey: '6f814e31-05d7-4924-bf86-3b644cd058a0',
            },
          })
          .then(resp => {
            return resp;
          })
          .catch(error => {
            throw error;
          });
      case 'delete':
        //console.log(action.data);
        return axios
          .delete(serv + action.payload, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),
              ApiKey: '6f814e31-05d7-4924-bf86-3b644cd058a0',
            },
            data: { id: action.data },
          })
          .then(resp => {
            return resp;
          })
          .catch(error => {
            throw error;
          });
      default:
        return axios
          .get(serv + action.payload, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),
            },
          })
          .then(resp => {
            return resp;
          })
          .catch(error => {
            throw error;
          });
    }
  };

  try {
    //console.log(socketio);

    if (action.delay) {
      yield delay(action.delay);
    }
    if (socketio.disconnected) {
      yield delay(100);

      yield axios
        .post(
          serv + 'auth/reAuthenticate',
          { sessionID: socketio.id },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('LUT'),
            },
          },
        )
        .then(resp => {
          localStorage.setItem('LUT', resp.data.accessToken);
          return resp;
        })
        .catch(error => {
          console.log(error);
          throw error;
        });

      response = yield call(API_CALL);
    } else {
      response = yield call(API_CALL);
    }
    //response = yield call(API_CALL);
    // yield delay(8000);
    yield put({
      type: 'API_TOGGLE_LOADING',
      payload: {
        payload: { isLoading: false, id: loadId },
      },
    });

    action.resolve(response);
    yield put({ type: 'API_GET_SUCCESS', payload: { data: response.data } });
  } catch (error) {
    //TEMP
    // console.log(response);
    // action.resolve(response);
    // yield put({ type: "API_GET_SUCCESS", payload: { data: [] } });
    console.log(error);
    yield put({
      type: 'API_TOGGLE_LOADING',
      payload: {
        payload: { isLoading: false, id: loadId },
      },
    });
    if (!error.response) {
      yield put({ type: 'API_GET_ERROR' });
      yield put({
        type: 'API_TOGGLE_LOADING',
        payload: { payload: { isLoading: false, force: true } },
      });
      yield put({
        type: 'MODAL_SHOW',
        payload: {
          title: 'Server Offline',
          desc: 'Please contact Administrator',
        },
      });

      //yield localStorage.clear();
      //window.location.reload();
      action.reject({ error: error, resp: response });
    } else {
      switch (error.response.status) {
        case 400:
          console.log(error.response);
          if (error.response.data.errorCode === 'validationError') {
            yield put({
              type: 'MODAL_SHOW',
              payload: {
                title: 'Notice',
                desc: `Validation Error.
                ${error.response.data.error.map(e => {
                  return e.constraints + ' ';
                })}`,
              },
            });
          } else {
            yield put({ type: 'API_GET_ERROR' });

            yield put({
              type: 'MODAL_SHOW',
              payload: {
                title: 'Server Error',
                desc: 'Please submit a ticket regarding this error',
              },
            });
            action.reject({ error: error, resp: response });
          }
          yield put({
            type: 'API_TOGGLE_LOADING',
            payload: { payload: { isLoading: false, force: true } },
          });
          break;
        case 401:
          action.reject({ error: error, resp: response });
          //yield localStorage.clear();
          yield put({ type: 'UI_CLEAR_SESSION_AUTH' });
          yield put({ type: 'API_LOGOUT' });
          yield localStorage.clear();
          //use proper history handling
          //yield call([history, history.push], "/login");
          // window.location.reload();

          // yield localStorage.clear();

          if (error.response.data.message === 'userActive') {
            yield put({
              type: 'MODAL_SHOW',
              payload: {
                title: 'Notice',
                desc: 'User active from another device.',
              },
            });
          } else {
            yield put({
              type: 'MODAL_SHOW',
              payload: {
                title: 'Session expired',
                desc: 'You have been logged out. Please login again',
              },
            });
          }

          yield put({
            type: 'API_TOGGLE_LOADING',
            payload: { payload: { isLoading: false, force: true } },
          });
          break;
        case 409:
          yield put({
            type: 'MODAL_SHOW',
            payload: {
              title: 'Duplicate',
              desc: 'Your input must be unique',
            },
          });

          break;
        default:
          yield put({ type: 'API_GET_ERROR' });
          yield put({
            type: 'API_TOGGLE_LOADING',
            payload: { payload: { isLoading: false, force: true } },
          });
          yield put({
            type: 'MODAL_SHOW',
            payload: {
              title: 'Server Error',
              desc: 'Please submit a ticket regarding this error',
            },
          });
          action.reject({ error: error, resp: response });
          break;
      }
    }
  }
}

export function* REAUTH(action) {
  try {
    yield put();
  } catch (error) {
    console.log(error);
  }
}
export function* LOADING_HANDLER(action) {
  //const response = yield select(getLoad);
  //console.log(action);
  try {
    if (action.payload)
      yield put({
        type: 'API_TOGGLE_LOADING',
        payload: action,
      });
    else {
      yield put({
        type: 'API_TOGGLE_LOADING',
        payload: action,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* APIRequestSaga() {
  yield takeEvery('API_REQUEST', API_REQUEST);
}

function* APIReAuth() {
  yield takeEvery('API_REAUTH', REAUTH);
}

export default function* rootSaga() {
  yield all([APIRequestSaga(), APIReAuth()]);
}
