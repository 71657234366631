import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import EnhancedTable from './components/EnhancedTable';
import { Post } from 'types/post';
import { Link as RouterLink } from 'react-router-dom';
import { Image } from 'mui-image';
import { width } from '@mui/system';
import moment from 'moment';
import ReactQuill, { Quill } from 'react-quill';
import useWindowDimensions from 'app/components/useWindowDimensions';
import contentAPI from 'api/contentAPI';
import { useDispatch, useSelector } from 'react-redux';
import { UIToggleGlobalModal } from 'actions/actionCreator';
import { TTableOperation } from 'types/table';
import Misc from '../../../functions/misc';
import { GlobalColumn } from 'app/components/globalPostCell';
import { specialPages } from 'types/links';
import { GlobalReducer } from 'types/globalReducer';

export const Management = lazyLoad(
  () => import('./Management'),
  module => module.ManagementContent,
);

const SkeletonCont = () => {
  return (
    <Paper variant="outlined" sx={{ px: '12px', py: '7.5px', my: '7.5px' }}>
      <Skeleton variant="text" width="50%" animation="wave" />
      <Skeleton
        variant="text"
        width="50%"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton
        variant="rectangular"
        height="250px"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton variant="text" width="60%" animation="wave" />
    </Paper>
  );
};
interface PostType {
  n: string;
  l: string;
}
export function ManagementContent(props: any) {
  //console.log(test);
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const userData = useSelector(
    (state: GlobalReducer) => state.apiData.userData,
  );

  //Quill.register('modules/VideoResize', test);

  const [posts, setPosts] = React.useState<Post[]>([]);

  const [data, setData] = React.useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const globalColumn = React.useMemo(() => [GlobalColumn], []);
  const [dialogAddPostOpen, setDialogAddPostOpen] = React.useState(false);

  const [isAnnouncementLoading, setIsAnnouncementLoading] =
    React.useState<Boolean>(false);
  const [isPostLoading, setIsPostLoading] = React.useState<Boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState({});

  const [tableOperation, setTableOperation] =
    React.useState<TTableOperation>('VIEW');

  const [selPostFilterPostLoc, setSelPostFilterPostLoc] = React.useState<
    PostType | null | undefined
  >(null);
  const [chkAllFilter, setChkAllFilter] = React.useState<Boolean>(true);
  const [chkIsAnnouncement, setChkIsAnnouncement] =
    React.useState<Boolean>(false);
  const fetchPosts = React.useCallback(async () => {
    //setIsPostLoading(true);

    let _posts = await contentAPI.getPosts(dispatch, {});
    //if (_posts.length > 3) _posts = _posts.slice(0, 3);
    setPosts([]);
    setPosts(_posts);

    //setIsPostLoading(false);
    //console.log(_posts);
  }, []);

  React.useEffect(() => {
    //console.log('trigger');
    fetchPosts();
  }, []);
  React.useEffect(() => {
    //console.log('trigger', tableOperation);
    if (tableOperation === 'REFRESH') {
      fetchPosts();
      setTableOperation('VIEW');
    }
    //console.log(tableOperation);
  }, [dialogAddPostOpen]);

  React.useEffect(() => {
    const refreshPost = async () => {
      let _posts: Post[] = [];

      const _filter = {
        // isAnnouncement: chkIsAnnouncement ? 1 : null,
        ...(chkIsAnnouncement ? { isAnnouncement: 1 } : undefined),
        ...(!chkAllFilter
          ? {
              postType: selPostFilterPostLoc?.l,
            }
          : undefined),
      };
      //console.log(_filter);

      if (chkAllFilter) {
        _posts = await contentAPI.getPosts(dispatch, { ..._filter });
      } else {
        _posts = await contentAPI.getPosts(dispatch, { ..._filter });
      }

      setPosts([]);
      setPosts(_posts);
    };
    refreshPost();
  }, [chkAllFilter, selPostFilterPostLoc, chkIsAnnouncement]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    // @ts-ignore
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            // @ts-ignore
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>Management</title>
        <meta name="description" content="Management" />
      </Helmet>
      <Box
        sx={{
          p: '15px',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h4">Content Management</Typography>
        {/* <Divider /> */}

        <Paper
          variant="outlined"
          sx={{ p: '15px', flex: 1, overflow: 'hidden', pb: '100px' }}
        >
          {isPostLoading ? (
            <>
              <SkeletonCont />
              <SkeletonCont />
              <SkeletonCont />
            </>
          ) : (
            <EnhancedTable
              columns={globalColumn}
              data={posts}
              setData={setData}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              setDialogAddPostOpen={setDialogAddPostOpen}
              dialogAddPostOpen={dialogAddPostOpen}
              onRowSelectStateChange={setSelectedRowIds}
              selectedRow={selectedRow}
              tableOperation={tableOperation}
              setTableOperation={setTableOperation}
              selPostFilterPostLoc={selPostFilterPostLoc}
              setSelPostFilterPostLoc={setSelPostFilterPostLoc}
              chkAllFilter={chkAllFilter}
              setChkAllFilter={setChkAllFilter}
              chkIsAnnouncement={chkIsAnnouncement}
              setChkIsAnnouncement={setChkIsAnnouncement}
              getRowProps={row => ({
                onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                  const target = e.target as HTMLElement;

                  //given this html e, how can i determine if its a div or h6
                  if (
                    target.tagName === 'DIV' ||
                    target.tagName === 'svg' ||
                    target.tagName === 'path'
                  ) {
                    // if (userData.pagePermissions?.includes(row.original.postType))
                    const allow = userData.pagePermissions?.find(
                      x =>
                        x.toUpperCase() === row.original.postType.toUpperCase(),
                    );
                    //console.log(row.original.postType.toUpperCase())
                    //console.log(userData);

                    if (allow || userData.type?.includes(0)) {
                      setDialogAddPostOpen(true);
                      setTableOperation('VIEW');
                      setSelectedRow(row.original);
                    } else {
                      dispatch(
                        UIToggleGlobalModal({
                          title: 'No Permission',
                          description: (
                            <>You are not allowed to modify this post</>
                          ),
                          isOpen: true,
                        }),
                      );
                    }
                  }

                  //console.log(userData);
                },
              })}
            />
          )}
        </Paper>
      </Box>
    </>
  );
}
