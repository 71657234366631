import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Post } from 'types/post';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Image } from 'mui-image';
import { width } from '@mui/system';
import moment from 'moment';
import ReactQuill from 'react-quill';
import useWindowDimensions from 'app/components/useWindowDimensions';
import contentAPI from 'api/contentAPI';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiStoreLoggedUser,
  login,
  SocketHandlerInit,
  UIToggleGlobalModal,
} from 'actions/actionCreator';
import { TTableOperation } from 'types/table';
import Misc from '../../../functions/misc';
import { GlobalReducer } from 'types/globalReducer';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import tigbauanChurch from '../../../assets/tigbauan-church.jpg';
import tigbauanLogoCircle from '../../../assets/tigbauanLogoCircle.png';
import AuthenticationAPI from '../../../api/authentication';
import ReCAPTCHA from 'react-google-recaptcha';
let CryptoJS = require('crypto-js');
export const Login = lazyLoad(
  () => import('./Login'),
  module => module.LoginContent,
);

const SkeletonCont = () => {
  return (
    <Paper variant="outlined" sx={{ px: '12px', py: '7.5px', my: '7.5px' }}>
      <Skeleton variant="text" width="50%" animation="wave" />
      <Skeleton
        variant="text"
        width="50%"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton
        variant="rectangular"
        height="250px"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton variant="text" width="60%" animation="wave" />
    </Paper>
  );
};

interface Debounce {
  delay: number;
  onPress: any;

  props?: any;
}
const DebouncedButton: React.FC<Debounce> = ({ delay, onPress, props }) => {
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!isDisabled) {
      // timeout elapsed, nothing to do
      return;
    }

    // isDisabled was changed to true, set back to false after `delay`
    const handle = setTimeout(() => {
      setDisabled(false);
    }, delay);
    return () => clearTimeout(handle);
  }, [isDisabled, delay]);

  const handleClick = e => {
    if (isDisabled) {
      return;
    }

    setDisabled(true);
    return onPress(e);
  };

  return (
    <LoadingButton
      size="small"
      onClick={handleClick}
      loading={isDisabled}
      loadingIndicator="Authenticating..."
      variant="contained"
      disabled={isDisabled}
    >
      Login
    </LoadingButton>
    // <Button
    //   // bg={appStyle.ORANGE_DARK}
    //   disabled={isDisabled}
    //   //isLoading={isDisabled}
    //   onClick={handleClick}
    //   variant="contained"

    // >
    //   <Typography>Login</Typography>
    // </Button>
  );
};

interface IValidate {
  txtUsername: boolean;
  txtPassword: boolean;
  loginMsg: string;
  recaptcha: boolean;
  errMsg: string | null;
}
const initialValidate = {
  txtUsername: false,
  txtPassword: false,
  recaptcha: false,
  loginMsg: '',
  errMsg: null,
};

export function LoginContent(props: any) {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: GlobalReducer) => state.apiData.isAuth);
  const navigate = useNavigate();
  const [txtUsername, setTxtUsername] = useState<string>('');
  const [txtPassword, setTxtPassword] = useState<string>('');
  const [UIValidate, setUIValidate] = useState<IValidate>(initialValidate);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const recaptchaRef = React.useRef();

  const SocketHandler = useSelector(
    (state: GlobalReducer) => state.SocketHandler.socket,
  );

  // React.useEffect(() => {
  //   dispatch(SocketHandlerInit());
  // }, []);
  // React.useEffect(() => {
  //   console.log(recaptcha);
  // }, [recaptcha]);

  const validateInputs = (cb: VoidFunction) => {
    let error: boolean = false;
    let errorComponents: IValidate = { ...initialValidate };

    if (txtUsername?.length === 0)
      errorComponents = { ...errorComponents, txtUsername: true };

    if (txtPassword?.length === 0)
      errorComponents = { ...errorComponents, txtPassword: true };

    if (recaptcha === null)
      errorComponents = {
        ...errorComponents,
        errMsg: 'Please click on the recaptcha to proceed.',
        recaptcha: true,
      };

    if (txtPassword?.length === 0 || txtUsername?.length === 0)
      errorComponents = {
        ...errorComponents,
        loginMsg: 'Username and Password should not be empty',
        txtPassword: true,
        txtUsername: true,
      };
    Object.keys(errorComponents).forEach(key => {
      if (errorComponents[key]) error = true;
    });

    setUIValidate(errorComponents);
    if (!error) {
      if (cb) cb();
    } else {
      enqueueSnackbar('Check Inputs', { variant: 'warning' });
    }
  };

  const authenticate = async () => {
    const loginData = {
      username: txtUsername,
      password: txtPassword,
      sessionID: SocketHandler.id,
    };
    const result = await AuthenticationAPI.authenticate(dispatch, loginData);
    if (result.status === 200) {
      const userData = result.data.user;
      const enLUD = CryptoJS.AES.encrypt(
        JSON.stringify(userData),
        process.env.REACT_APP_SEK,
      );

      localStorage.setItem('LUT', result.data.accessToken);
      localStorage.setItem('LUD', enLUD);
      dispatch(apiStoreLoggedUser(userData, () => {}));
      dispatch(login());
      navigate('/post-management');
    } else {
      setUIValidate({
        ...UIValidate,
        txtPassword: true,
        txtUsername: true,
        loginMsg: 'Username or password is incorrect',
      });
      enqueueSnackbar('Username or password is incorrect', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Management" />
      </Helmet>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          pointerEvents: 'none',
          zIndex: -5,
          opacity: 0.3,
        }}
      >
        <Image
          sx={{}}
          src={tigbauanChurch}
          duration={2500}
          fit="cover"
          width="100%"
          height="100%"
        />
      </Box>

      <Grid sx={{ height: '100vh' }} container>
        <Grid
          sx={{ alignSelf: 'center', pl: 3 }}
          xs={12}
          sm={12}
          md={6}
          lg={6}
          item
        >
          <Paper
            variant="elevation"
            elevation={4}
            className="animate__animated animate__fadeInLeft"
            sx={{ px: 3, py: 4, backgroundColor: 'rgba(256,256,256,0.90)' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Image
                src={tigbauanLogoCircle}
                duration={2500}
                bgColor="none"
                width="50%"
                fit="cover"
              />
            </Box>

            <Typography variant="h4" sx={{ textAlign: 'center', mb: 0 }}>
              Municipality of Tigbauan
            </Typography>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 5 }}>
              Website Content Management
            </Typography>
            <Stack spacing={2} sx={{ px: 3 }}>
              <TextField
                label="Username"
                type="text"
                //variant="filled"
                autoComplete="username"
                value={txtUsername}
                onChange={e => setTxtUsername(e.target.value)}
                error={UIValidate.txtUsername}
              />
              {/* <TextField
              label="Office ID"
              type="text"
              //variant="filled"
            /> */}
              <TextField
                id="outlined-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={txtPassword}
                onChange={e => setTxtPassword(e.target.value)}
                error={UIValidate.txtPassword}
                helperText={UIValidate.loginMsg}
              />
              <Box
                sx={{
                  'div > div': {
                    display: 'flex',
                    justifyContent: 'center',
                  },

                  ...(UIValidate.errMsg && {
                    'div > div > div > div > iframe': {
                      border: '1px solid',
                      borderColor: 'error.main',
                      borderRadius: 1,
                    },
                  }),
                }}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Le9SzkgAAAAAPvP1uAVuxz6gbaV5lz16dGFuQ1c"
                  onChange={e => setRecaptcha(e)}
                  onExpire={e => setRecaptcha(e)}
                />
              </Box>
              {UIValidate.errMsg && (
                <Typography sx={{ fontSize: '0.8rem', color: 'error.main' }}>
                  {UIValidate.errMsg}
                </Typography>
              )}

              <DebouncedButton
                delay={2000}
                onPress={e => {
                  //e.persist();
                  validateInputs(authenticate);
                }}
              />
              <Typography
                sx={{
                  textAlign: 'center',
                  pt: 3,
                  fontSize: '0.8rem',
                  color: 'error.main',
                }}
              >
                To increase website security, we strongly advice to use this CMS
                only in Tigbauan Municipal Offices.
              </Typography>
              <Typography
                sx={{ textAlign: 'center', pt: 3, fontSize: '0.7rem' }}
              >
                V 1.2.4
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} item></Grid>
      </Grid>
    </>
  );
}
