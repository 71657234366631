import React from 'react';

import PropTypes from 'prop-types';

import TablePaginationActions from './TablePaginationActions';

import TableToolbar from './TableToolbar';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Checkbox,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableFooter,
  TablePagination,
  Table,
  Typography,
  Button,
  Box,
  Paper,
} from '@mui/material';

const IndeterminateCheckbox = React.forwardRef(
  // @ts-ignore
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/*
 // @ts-ignore */}
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const inputStyle = {
  padding: 0,
  margin: 0,
  border: 0,
  background: 'transparent',
};

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      style={inputStyle}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

EditableCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any.isRequired,
  }),
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }),
  column: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  updateMyData: PropTypes.func.isRequired,
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

const EnhancedTable = ({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  setDialogAddPostOpen,
  dialogAddPostOpen,
  onRowSelectStateChange,
  getRowProps,
  selectedRow,
  tableOperation,
  setTableOperation,
  selPostFilterPostLoc,
  setSelPostFilterPostLoc,
  chkAllFilter,
  setChkAllFilter,
  chkIsAnnouncement,
  setChkIsAnnouncement,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    // hooks => {
    //   hooks.allColumns.push(columns => [
    //     // Let's make a column for selection
    //     {
    //       id: 'selection',
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox.  Pagination is a problem since this will select all
    //       // rows even though not all rows are on the current page.  The solution should
    //       // be server side pagination.  For one, the clients should not download all
    //       // rows in most cases.  The client should only download data for the current page.
    //       // In that case, getToggleAllRowsSelectedProps works fine.
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <Box sx={{ m: 0, p: 0 }}>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </Box>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // },
  );

  React.useEffect(() => {
    onRowSelectStateChange?.(selectedFlatRows);
  }, [onRowSelectStateChange, selectedFlatRows]);
  // React.useEffect(() => {
  //   if (selectedFlatRows && onRowSelectStateChange)
  //     onRowSelectStateChange(selectedFlatRows);
  // }, [selectedFlatRows]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };

  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i));

  const deleteUserHandler = event => {
    const newData = removeByIndexs(
      data,
      Object.keys(selectedRowIds).map(x => parseInt(x, 10)),
    );
    setData(newData);
  };

  const addUserHandler = user => {
    const newData = data.concat([user]);
    setData(newData);
  };

  // Render the UI for your table
  return (
    <>
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        deleteUserHandler={deleteUserHandler}
        addUserHandler={addUserHandler}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        setDialogAddPostOpen={setDialogAddPostOpen}
        dialogAddPostOpen={dialogAddPostOpen}
        selectedRow={selectedRow}
        tableOperation={tableOperation}
        setTableOperation={setTableOperation}
        selPostFilterPostLoc={selPostFilterPostLoc}
        setSelPostFilterPostLoc={setSelPostFilterPostLoc}
        chkAllFilter={chkAllFilter}
        setChkAllFilter={setChkAllFilter}
        chkIsAnnouncement={chkIsAnnouncement}
        setChkIsAnnouncement={setChkIsAnnouncement}
      />
      <TableContainer
        sx={{
          // maxHeight: '75vh !important',
          // height: '75vh !important',
          height: '95%',
          display: 'flex',

          flex: 1,
          overflow: 'auto',
          py: 5,
          '@media (min-width: 1200px)': {
            px: '15%',
          },
          '@media (max-width: 900px)': {
            px: '10%',
          },
        }}
      >
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...(column.id === 'selection'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    {column.render('Header')}
                    {column.id !== 'selection' ? (
                      <TableSortLabel
                        active={column.isSorted}
                        // react-table has a unsorted state which is not treated here
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            sx={{
              '& tr:hover': {
                //backgroundColor: 'secondary.main',
                border: 'dotted  black 5px'
              },
            }}
          >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                //@ts-ignore
                <TableRow {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {/* <Typography>TEST</Typography> */}
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                  {/* <Paper variant="outlined">
                <Button variant="contained">Modify</Button>
              </Paper> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{  }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
          colSpan={3}
          count={data.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
    </>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
};

export default EnhancedTable;
