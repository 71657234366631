export interface User {
  id?: number;
  name?: string;
  username?: string;
  types: number[];
  type?: number[];
  isLocked?: number;
  isSuspended?: number;
  pagePermissions?: string[];
  datePosted?: string;
  dateEdited?: string;
  password?: string;
  readonly uniqueId?: string;
  readonly sessionId?: string;
  readonly files?: any;
}

export interface IAuthenticate {
  username: string;
  password: string;
  sessionID: string;
}
export const userTypes = [
  { value: 0, name: 'admin' },
  { value: 1, name: 'user' },
];
export const initUserType = { value: 1, name: 'user' };
export interface IUserType {
  value: number;
  name: string;
}
