import { IGlobalModal } from 'types/globalReducer';
import { ImageDetail } from 'types/imageDetails';

type requestType = 'get' | 'post' | 'put' | 'delete';

export function apiRequest(
  type: requestType,
  data: any,
  loadingMsg: string,
  url: string,
  resolve: any,
  reject: any,
  delay?: number,
) {
  return {
    type: 'API_REQUEST',
    reqType: type,
    loadingMsg: loadingMsg,
    data,
    payload: url,
    resolve: resolve,
    reject: reject,
    delay: delay,
  };
}

export function uiSetSessionAuth(
  timeRemind: NodeJS.Timeout,
  timeLogout: NodeJS.Timeout,
) {
  return {
    type: 'UI_SET_SESSION_AUTH',
    payload: { timeRemind: timeRemind, timeLogout: timeLogout },
  };
}

export function uiClearSessionAuth() {
  return {
    type: 'UI_CLEAR_SESSION_AUTH',
    payload: {},
  };
}

export function SocketHandlerInit(cb?: VoidFunction) {
  return {
    type: 'SOCKET_HANDLER_INIT',
    payload: cb,
  };
}

export function login() {
  return {
    type: 'API_LOGIN',
  };
}
export function logout(cb: VoidFunction) {
  return {
    type: 'API_LOGOUT',
    payload: cb,
  };
}
export function uiToggleDocViewer(
  toggle: boolean,
  fileName?: string,
  uniqueID?: string,
) {
  return {
    type: 'UI_TOGGLE_DOC_VIEWER_WDATA',
    payload: { data: toggle, fileName: fileName, uniqueID: uniqueID },
  };
}
export function UIToggleGlobalModal(globalModal: IGlobalModal) {
  return {
    type: 'TOGGLE_GLOBAL_MODAL',
    payload: { ...globalModal },
  };
}
export function apiStoreImageDetails(imageDetail: ImageDetail[]) {
  return {
    type: 'API_STORE_IMAGE_DETAILS',
    payload: { imageDetail: imageDetail },
  };
}
export function apiStoreLoggedUser(user: any, cb: VoidFunction) {
  return {
    type: 'API_STORE_USER',
    payload: { data: user, cb: cb },
  };
}
export function apiSetAuthenticated(isAuthenticated: any) {
  return {
    type: 'API_SET_AUTHENTICATED',
    payload: { isAuthenticated: isAuthenticated },
  };
}
export function apiSetSocketInstance(socket: any) {
  return {
    type: 'SET_SOCKET_INSTANCE',
    payload: socket,
  };
}
export function apiSetSocketID(socketId: any) {
  return {
    type: 'SET_SOCKET_ID',
    payload: socketId,
  };
}
